import React from 'react'

import { get } from 'lodash'
import {
  FormField as BrFormField,
  ErrorMessage,
  Select,
} from '@vwfs-bronson/bronson-react'
import { arrayToValueLabel } from '../../services/common/form'

export function Dropdown({
  name,
  notion,
  labelText,
  labelForId,
  defaultValue,
  formProps,
  onChange,
  disabled,
  list,
}) {
  return (
    <BrFormField
      name={name}
      type="select"
      testId="select"
      notion={notion}
      labelForId={labelForId ?? name}
      labelText={labelText}
      errorMessage={
        get(formProps?.errors, name) && get(formProps?.touched, name) ? (
          <ErrorMessage>
            {get(formProps?.touched, name) && get(formProps?.errors, name)}
          </ErrorMessage>
        ) : (
          ''
        )
      }
    >
      <Select
        id={name}
        testId={name}
        onChange={
          onChange ?? (e => formProps.setFieldValue(name, e.target.value))
        }
        value={formProps.values[name] ?? ' '}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {name === 'nationality' ||
        name === 'brand' ||
        name === 'neighborhood' ||
        name === 'dealerCitySelect' ||
        name === 'numberOfDependentChildren'
          ? list.map((item, index) => (
              <Select.Item key={`${item?.value}-${index}`} value={item?.value}>
                {item?.label}
              </Select.Item>
            ))
          : arrayToValueLabel(list).map((item, index) => (
              <Select.Item key={`${item.value}-${index}`} value={item.value}>
                {item.label}
              </Select.Item>
            ))}
      </Select>
    </BrFormField>
  )
}
