import calculation from './calculation.json'
import config from './config.json'
import confirmation from './confirmation.json'
import contact from './contact.json'
import form from './form.json'
import data from './data.json'
import error from './error.json'
import faq from './faq.json'
import footer from './footer.json'
import header from './header.json'
import rejection from './rejection.json'
import validation from './validation.json'
import marketingcards from './marketingcards.json'
import creditcheck from './creditcheck.json'

export const i18n = {
  calculation,
  config,
  confirmation,
  contact,
  data,
  form,
  error,
  faq,
  footer,
  header,
  rejection,
  validation,
  marketingcards,
  creditcheck,
}
