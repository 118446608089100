export const FETCH_TAX_ID_PENDING = 'taxId//FETCH_TAX_ID_PENDING'

export const FETCH_TAX_ID_DONE = 'taxId//FETCH_TAX_ID_DONE'

export const FETCH_TAX_ID_ERROR = 'taxId//FETCH_TAX_ID_ERROR'

export function pending() {
  return {
    type: FETCH_TAX_ID_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_TAX_ID_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_TAX_ID_ERROR,
    payload: {
      err,
    },
  }
}

export function getTaxId(store) {
  return store.taxId.data
}

const initialState = {
  pending: false,
  data: [],
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TAX_ID_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_TAX_ID_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_TAX_ID_ERROR:
      return {
        ...state,
        data: [],
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
