/* eslint-disable react/no-array-index-key */
import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  SectionHeading,
  Spinner,
  Accordion,
  InfoIcon,
  Paragraph,
  Card,
} from '@vwfs-bronson/bronson-react'
import {
  formatCurrency,
  formatWithMonths,
} from '../../../services/common/format'
import { ContentSection } from '../../../components/ContentSection'
import { HtmlContent } from '../../../components/HtmlContent'
import { DefinitionList } from '../../../components/DefinitionList'
import { getFinancialProduct } from '../../../services/redux/features/financial-products.redux'
import {
  calculatePayments,
  removeNulls,
  useTraceUpdate,
} from '../../../services/data/utils'
import { Analytics } from '../../../services/analytics'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { saveCalculationData } from '../../../services/redux/features/calculation.redux'

const PaymentSummary = props => {
  const {
    values,
    isLoadingPrice,
    pricingData,
    vehicleData,
    selectedFinancialProduct,
    storefrontData,
  } = props
  useTraceUpdate(props)

  const { t } = useTranslation()
  const rate =
    values?.downPaymentRate ??
    storefrontData?.financialData?.calculation?.downPaymentPercentage
  const calculationResult = calculatePayments(
    rate,
    vehicleData?.price,
    pricingData
  )

  React.useEffect(() => {
    if (removeNulls(calculationResult).length) {
      saveCalculationData(calculationResult)
    }
  }, [calculationResult, pricingData, rate])

  Analytics.mergeDefaults({
    product: [
      {
        attributes: {
          downPaymentAmount: calculationResult?.totalDownPayment,
        },
      },
    ],
  })

  return (
    <>
      <ContentSection altBackground>
        <SectionHeading number="5">
          {t('calculation:simulation:heading')}
        </SectionHeading>
        {isLoadingPrice && <Spinner small={false} center />}
        {!isLoadingPrice && !!calculationResult && (
          <>
            <DefinitionList>
              {calculationResult && calculationResult?.totalDownPayment && (
                <>
                  <DefinitionList.Title>
                    {t('calculation:simulation:hitchDownPayment')}
                  </DefinitionList.Title>
                  <DefinitionList.Item>
                    {formatCurrency(
                      calculationResult?.totalDownPayment,
                      'symbol'
                    )}
                  </DefinitionList.Item>
                </>
              )}
              {!!calculationResult?.bookingAmount &&
                !!calculationResult?.remainingHitch && (
                  <React.Fragment>
                    <DefinitionList.Title>
                      {t('calculation:simulation:bookingAmount')}
                    </DefinitionList.Title>
                    <DefinitionList.Item>
                      {formatCurrency(
                        calculationResult?.bookingAmount,
                        'symbol'
                      )}
                    </DefinitionList.Item>
                    <DefinitionList.Title>
                      {t('calculation:simulation:remainingHitch')}
                    </DefinitionList.Title>
                    <DefinitionList.Item>
                      {formatCurrency(
                        calculationResult?.remainingHitch,
                        'symbol'
                      )}
                    </DefinitionList.Item>
                  </React.Fragment>
                )}
              {calculationResult?.loanAmount && (
                <>
                  <DefinitionList.Title>
                    {t('calculation:simulation:loanAmount')}
                  </DefinitionList.Title>
                  <DefinitionList.Item>
                    {formatCurrency(calculationResult?.loanAmount, 'symbol')}
                  </DefinitionList.Item>
                </>
              )}
              {!!calculationResult?.initialPayment && (
                <>
                  <DefinitionList.Title>
                    <span className="c-info-icon-text">
                      {t('calculation:simulation:initialPayment')}
                    </span>

                    <InfoIcon icon="semantic-info">
                      {t('calculation:simulation:initialPaymentTooltip')}
                    </InfoIcon>
                  </DefinitionList.Title>
                  <DefinitionList.Item>
                    {formatCurrency(
                      calculationResult?.initialPayment,
                      'symbol'
                    )}
                  </DefinitionList.Item>
                </>
              )}
              <DefinitionList.Title>
                {t('calculation:simulation:term')}
              </DefinitionList.Title>
              <DefinitionList.Item>
                {formatWithMonths(values?.duration, t)}
              </DefinitionList.Item>
            </DefinitionList>
            <hr />
            <DefinitionList horizontal horizontal66to33>
              <DefinitionList.Title>
                {t('calculation:simulation:monthlyPayment')}
              </DefinitionList.Title>
              <DefinitionList.Item>
                {formatCurrency(pricingData?.installmentAmount, 'symbol')}
              </DefinitionList.Item>
            </DefinitionList>
          </>
        )}
        {selectedFinancialProduct?.legalTerms && (
          <Card element="article">
            <Accordion>
              <Accordion.Item title={t('calculation:simulation:legalHeadline')}>
                <Paragraph>
                  <HtmlContent>
                    {selectedFinancialProduct?.legalTerms}
                  </HtmlContent>
                </Paragraph>
              </Accordion.Item>
            </Accordion>
          </Card>
        )}
      </ContentSection>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { values } = ownProps
  const { productId } = values
  return {
    storefrontData: getStorefrontData(state),
    selectedFinancialProduct: getFinancialProduct(
      state,
      parseInt(productId, 10),
      values.occupation === 'employee'
    ),
  }
}

export default connect(mapStateToProps, { saveCalculationData })(PaymentSummary)
