export const FETCH_PRICING_PENDING = 'pricing//FETCH_PRICING_PENDING'

export const FETCH_PRICING_DONE = 'pricing//FETCH_PRICING_DONE'

export const FETCH_PRICING_ERROR = 'pricing//FETCH_PRICING_ERROR'

export const SET_PRICE = 'pricing//SET_PRICE'

export const RESET_PRICING = 'pricing//RESET_PRICING'

export function pending() {
  return {
    type: FETCH_PRICING_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_PRICING_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_PRICING_ERROR,
    payload: {
      err,
    },
  }
}

export function reset() {
  return {
    type: RESET_PRICING,
  }
}

export function getPricingData(store) {
  return store.pricing.data
}

const initialState = {
  pending: false,
  data: {},
  price: 0,
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRICING_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_PRICING_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_PRICING_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }
    case SET_PRICE:
      return {
        ...state,
        price: action.payload.data,
      }
    case RESET_PRICING:
      return initialState

    default:
      return state
  }
}
