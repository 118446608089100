import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { history } from '../routing'
import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import storefrontReducer from './features/storefront.redux'
import pricingReducer from './features/pricing.redux'
import amortizationTableReducer from './features/amortization-table.redux'
import catalogReducer from './features/catalog.redux'
import financialProductsReducer from './features/financial-products.redux'
import demographicsReducer from './features/demographics.redux'
import statesReducer from './features/states.redux'
import taxIdReducer from './features/tax-id.redux'
import dealerReducer from './features/dealers.redux'
import calculationReducer from './features/calculation.redux'

const reducer = combineReducers({
  form: formReducer,
  dataModel: dataModelsReducer,
  storefront: storefrontReducer,
  pricing: pricingReducer,
  amortizationTable: amortizationTableReducer,
  catalog: catalogReducer,
  financialProduct: financialProductsReducer,
  demographics: demographicsReducer,
  states: statesReducer,
  taxId: taxIdReducer,
  calculation: calculationReducer,
  dealers: dealerReducer,
  router: connectRouter(history),
})
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  {},
  composeEnhancers(applyMiddleware(routerMiddleware(history)))
)

export { store }
