/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  SectionHeading,
  Spinner,
  TileSelectGroup,
  TileSelect,
  Layout,
} from '@vwfs-bronson/bronson-react'
import { ContentSection } from '../../../components/ContentSection'
import { isMercadoLibre } from '../../../services/data/utils'

const PlanSelection = ({
  values,
  setFieldValue,
  financialProducts,
  setSettingOccupation,
}) => {
  const { t } = useTranslation()
  if (!financialProducts) {
    setSettingOccupation(true)
    return <Spinner center />
  }
  return (
    financialProducts && (
      <ContentSection altBackground>
        <SectionHeading number={2}>
          {!isMercadoLibre() && t('calculation:subheading')}
        </SectionHeading>
        <TileSelectGroup>
          <Layout equalHeight>
            {financialProducts &&
              financialProducts.map(product => (
                <div
                  key={product.id}
                  className={
                    isMercadoLibre() && financialProducts.length === 1
                      ? 'o-layout__item  u-1/1  u-1/1@s'
                      : 'o-layout__item  u-1/2  u-1/1@s'
                  }
                >
                  <TileSelect
                    title={product.name}
                    id={product.id.toString()}
                    checked={values.productId === product.id.toString()}
                    onChange={() => {
                      setFieldValue('productId', product.id.toString())
                    }}
                  >
                    {product.summary}
                    <br />
                    <br />
                    {product.description}
                  </TileSelect>
                </div>
              ))}
          </Layout>
        </TileSelectGroup>
      </ContentSection>
    )
  )
}

export default PlanSelection
