export const FETCH_DEMOGRAPHICS_PENDING =
  'demographics//FETCH_DEMOGRAPHICS_PENDING'

export const FETCH_DEMOGRAPHICS_DONE = 'demographics//FETCH_DEMOGRAPHICS_DONE'

export const FETCH_DEMOGRAPHICS_ERROR = 'demographics//FETCH_DEMOGRAPHICS_ERROR'

export const RESET_DEMOGRAPHICS = 'demographics//RESET_DEMOGRAPHICS'

export function pending() {
  return {
    type: FETCH_DEMOGRAPHICS_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_DEMOGRAPHICS_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_DEMOGRAPHICS_ERROR,
    payload: {
      err,
    },
  }
}

export function reset() {
  return {
    type: RESET_DEMOGRAPHICS,
  }
}

export function getDemographics(store) {
  return store.demographics.data
}

const initialState = {
  pending: false,
  data: [],
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEMOGRAPHICS_PENDING:
      return {
        ...state,
        data: [],
        pending: true,
        error: null,
      }

    case FETCH_DEMOGRAPHICS_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_DEMOGRAPHICS_ERROR:
      return {
        ...state,
        data: [],
        pending: false,
        error: true,
      }
    case RESET_DEMOGRAPHICS:
      return initialState

    default:
      return state
  }
}
