import { axios } from '..'
import * as actions from '../../redux/features/dealers.redux'
import { store } from '../../redux'
import { getUriParameter } from '../../common/format'
import { getApiBrand } from '../../../config'
import { LoggerError } from '../../common/logger'

const { REACT_APP_PROXY_API_KEY, REACT_APP_GET_DEALERS } = process.env

function getDealers(brand, params) {
  const uriParameter = getUriParameter(params)
  const url = `${REACT_APP_GET_DEALERS}/dealers/${getApiBrand(brand)}${
    uriParameter ? `?${uriParameter}` : ''
  }`
  return axios
    .get(url, {
      headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
      validateStatus: statusCode => statusCode === 200,
    })
    .catch(e => LoggerError(e))
}

export function getDealerData(dealerId) {
  const url = `${REACT_APP_GET_DEALERS}/dealer/${dealerId}`
  return axios
    .get(url, {
      headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
      validateStatus: statusCode => statusCode === 200,
    })
    .then(response => response)
    .catch(e => LoggerError(e))
}

export function getDealerBrandCodeData(dealerBrandCode) {
  const url = `${REACT_APP_GET_DEALERS}/dealer/brand-code/${dealerBrandCode}`
  return axios
    .get(url, {
      headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
      validateStatus: statusCode => statusCode === 200,
    })
    .then(response => response)
    .catch(e => LoggerError(e))
}

export function loadDealersToRedux(brand, vehicleBrand, params = {}) {
  if (brand) {
    return brand.toLowerCase() === 'dwa'
      ? internalLoadDealersToRedux(store.dispatch, vehicleBrand, {
          ...params,
          hasDWA: true,
        })
      : internalLoadDealersToRedux(store.dispatch, brand, params)
  }
  resetDealersRedux()
  return new Promise(resolve => resolve({}))
}

function internalLoadDealersToRedux(dispatch, brand, params) {
  dispatch(actions.pending())
  return getDealers(brand, params)
    .then(response => {
      return response && response.dealers || []
    })
    .catch(() => resetDealersRedux())
    .then(data => dispatch(actions.done(data)))
}

export function resetDealersRedux() {
  store.dispatch(actions.reset())
}
