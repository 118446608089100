import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Spinner, Button } from '@vwfs-bronson/bronson-react'
import { Form, Formik } from 'formik'

import {
  ANALYTICS_DEFAULTS,
  analyticsBrandsMap,
  analyticsOccupationMap,
  getApiBrand,
} from '../../config'

import { Analytics } from '../../services/analytics'
import CalculationPageWizard from './wizard'
import { getDataModels } from '../../services/redux/features/data-models.redux'
import { scrollToElement } from '../../services/common/form'
import ValidationSchema from './validation-schema'
import { getInitialValues } from './field-definitions'
import { store } from '../../services/redux'
import { saveFormData } from '../../services/redux/features/form.redux'
import { ContentSection } from '../../components/ContentSection'
import { history } from '../../services/routing'
import Sections from './Sections'
import { Storage } from '../../services/storage'
import { documentTitle } from '../../services/common/format'
import { getStorefrontData } from '../../services/redux/features/storefront.redux'
import {
  getDealerId,
  setDealerId,
} from '../../services/redux/features/dealers.redux'
import { finishSimulation } from '../../services/api/journey/simulation/simulation.service'

const CalculationPage = props => {
  const [isLoadingPrice, setLoadingPrice] = React.useState(false)
  const { t } = useTranslation()
  const { dataModels, storefrontData, dealerid } = props
  const [isSubmiting, setSubmiting] = React.useState(false)
  const [isValidating, setValidating] = React.useState(false)
  const [
    amortizationTableRendered,
    setAmortizationTableRendered,
  ] = React.useState(false)

  const storefrontId =
    storefrontData?.dataStorage?.id ??
    storefrontData?.dataStorage?.data?.transaction?.id ??
    ''
  const [errors, setErrors] = React.useState()
  const landingStorage = Storage()

  React.useEffect(() => {
    document.title = documentTitle(t, 'calculation')
    Analytics.pageBottom()
    Analytics.setDefaults(ANALYTICS_DEFAULTS)

    landingStorage.get('returningCustomer').then(val => {
      if (!val) {
        landingStorage.set('returningCustomer', true)
        Analytics.track({
          pageName: 'Calculation page',
          events: [
            {
              eventInfo: {
                eventType: 'pageView',
                eventAction: 'Success',
              },
            },
            {
              eventInfo: {
                eventType: 'Product journey',
                eventAction: 'Start',
              },
            },
          ],
          browserResolutionBreakpoint: window.innerWidth,
        })
      } else if (val) {
        Analytics.track({
          pageName: 'Calculation page',
          events: [
            {
              eventInfo: {
                eventType: 'pageView',
                eventAction: 'Success',
              },
            },
          ],
          browserResolutionBreakpoint: window.innerWidth,
        })
      }
      Analytics.page()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  React.useEffect(() => {
    if (
      errors !== undefined &&
      Object.entries(errors).length !== 0 &&
      errors.constructor === Object &&
      !isValidating &&
      !isSubmiting
    ) {
      scrollToElement('.is-error', 'center')
    }
  }, [isValidating, isSubmiting, errors])

  const formValues = React.useRef({})
  let currentPrice = 0

  const createCalculationData = () => ({
    employment: {
      occupation: formValues.current.occupation,
    },
    vehicleData: {
      usedNew: formValues.current.usedNew,
      brand: getApiBrand(formValues.current.brand),
      model: formValues.current.model,
      modelYear: formValues.current.modelYear,
      version: formValues.current.version,
      price: formValues.current.price,
    },
    calculation: {
      productId: formValues.current.productId,
      downPaymentRate:
        formValues.current.downPaymentRate ||
        storefrontData?.financialData?.calculation?.downPaymentPercentage,
      duration: formValues.current.duration,
    },
  })

  const handleAnalytics = () => {
    Analytics.mergeDefaults({
      product: [
        {
          attributes: {
            duration: formValues.current.duration,
          },
          vehicleModel: [
            {
              manufacturer: analyticsBrandsMap[formValues.current.brand],
              name: formValues.current.model,
              modelVariation: formValues.current.version,
              year: formValues.current.modelYear,
              endPrice_localCurrency: currentPrice,
              descriptionLong: formValues.current.version,
              typeOfUse: analyticsOccupationMap[formValues.current.occupation],
            },
          ],
        },
      ],
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmiting(true)

    if (!isValidating && formValues.current) {
      const calculationData = createCalculationData()
      store.dispatch(saveFormData(calculationData))
      finishSimulation(storefrontId)
      handleAnalytics()
      history.push({
        pathname: dealerid ? `/form?dealerid=${dealerid}` : '/form',
        search: window.location.search,
      })
    }
    setSubmiting(false)
  }

  if (!Object.keys(dataModels).length) {
    return (
      <div className="u-p-xlarge">
        <Spinner small={false} section center />
      </div>
    )
  }

  const onKeyDown = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault()
      e.target.blur()
    }
  }

  const priceUpdateCallback = price => {
    currentPrice = price ? parseFloat(price.replace(',', '')) : null
    formValues.current.price = price
  }

  const usedCar = storefrontData?.usedCar

  return (
    <React.Fragment>
      <Formik
        initialValues={getInitialValues(dataModels)}
        validationSchema={ValidationSchema}
        onSubmit={() => {}}
      >
        {formProps => {
          const { values, setFieldValue, isValid, resetForm } = formProps
          setSubmiting(formProps.isSubmitting)
          setValidating(formProps.isValidating)
          setErrors(formProps.errors)
          formValues.current = values
          const canGoForward =
            !!(
              values &&
              values.occupation &&
              ((values.version &&
                dataModels.vehicleModel &&
                dataModels.vehicleModel.id) ||
                (values.version && values.price))
            ) === true
          return (
            <>
              <CalculationPageWizard />
              <Form onKeyDown={onKeyDown}>
                <Sections
                  dataModels={dataModels}
                  values={values}
                  formProps={formProps}
                  usedCar={usedCar}
                  setFieldValue={setFieldValue}
                  priceUpdateCallback={priceUpdateCallback}
                  amortizationRendered={setAmortizationTableRendered}
                  setErrors={setErrors}
                  resetForm={resetForm}
                  isLoadingPrice={isLoadingPrice}
                  setLoadingPrice={setLoadingPrice}
                />
                <ContentSection>
                  {amortizationTableRendered && canGoForward && (
                    <div className="u-text-right" data-theme>
                      <Button
                        onClick={handleSubmit}
                        disabled={
                          isValidating ||
                          isSubmiting ||
                          !isValid ||
                          isLoadingPrice
                        }
                      >
                        {t('calculation:continueButton')}
                      </Button>
                    </div>
                  )}
                </ContentSection>
              </Form>
            </>
          )
        }}
      </Formik>
      {isSubmiting ? <Spinner small={false} fullPage /> : null}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    dataModels: getDataModels(state),
    storefrontData: getStorefrontData(state),
    dealerid: getDealerId(state),
  }
}

export default connect(mapStateToProps, { setDealerId })(CalculationPage)
