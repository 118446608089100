import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { withRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import './services/i18n'
import { history } from './services/routing'
import { store } from './services/redux'
import { scrollToElement } from './services/common/form'
import App from './App'

const PositionRestore = withRouter(props => {
  const [location, setLocation] = React.useState(props.location.pathname)
  if (location !== props.location.pathname) {
    setLocation(props.location.pathname)
    if (props.location.state && props.location.state.sectionId) {
      setTimeout(() => {
        const element = document.getElementById(props.location.state.sectionId)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    } else {
      setTimeout(() => {
        scrollToElement('.c-header')
      }, 0)
    }
  }

  return null
})

function Init() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PositionRestore />
        <App />
      </ConnectedRouter>
    </Provider>
  )
}

if (module.hot) {
  module.hot.accept(render)
}

function render() {
  const rootElement = document.getElementById('root')
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<Init />, rootElement)
}

render()
