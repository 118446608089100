import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  SectionHeading,
  RadioButton,
  RadioButtonGroup,
  FormField,
} from '@vwfs-bronson/bronson-react'
import { ContentSection } from '../../../components/ContentSection'
import { getBrandName } from '../../../config'

const OccupationRadio = ({
  dataModels,
  values,
  setFieldValue,
  resetData,
  onChangeValue,
  settingOccupation,
}) => {
  const { t } = useTranslation()
  const brandName = getBrandName()
  return (
    <ContentSection altBackground>
      <SectionHeading number={5}>
        {t('calculation:occupationRadio:heading')}
      </SectionHeading>
      <FormField name="occupation">
        <RadioButtonGroup>
          {dataModels.occupationTypes.map(item => (
            <RadioButton
              key={item.value}
              name="occupation"
              value={item.value.toString()}
              checked={item.value.toString() === values.occupation.toString()}
              onChange={e => {
                resetData(
                  ['vwfs', 'dwa', 'vwcv'].includes(brandName)
                    ? ''
                    : values.brand,
                  setFieldValue,
                  item.value.toString()
                )
                onChangeValue()
                settingOccupation(false)
                setFieldValue('occupation', e.target.value)
              }}
            >
              {item.label}
            </RadioButton>
          ))}
        </RadioButtonGroup>
      </FormField>
    </ContentSection>
  )
}

export default OccupationRadio
