export const productIdTypes = [
  {
    value: '2',
    label: 'Volkswagen ¡Ya!',
  },
  {
    value: '1',
    label: 'Credit:',
  },
]
