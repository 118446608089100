/* eslint-disable prefer-destructuring */
import isEmpty from 'lodash/isEmpty'
import { reset as resetFinancialProducts } from '../../services/redux/features/financial-products.redux'
import { reset as resetPricing } from '../../services/redux/features/pricing.redux'
import { reset as resetAmortization } from '../../services/redux/features/amortization-table.redux'
import { store } from '../../services/redux'
import { getBrandName } from '../../config'
import { loadFinancialDataToRedux } from '../../services/api/catalog/financial-products.service'
import { isMercadoLibre } from '../../services/data/utils'
import { LoggerDebug, LoggerError } from '../../services/common/logger'

const getUsedNewValue = brand => {
  try {
    const { usedCar } = store.getState().storefront.data
    if (brand === 'dwa' || usedCar) {
      return 'used'
    }
    if (brand === 'vwfs') {
      return 'new'
    }
  } catch {
    return 'new'
  }
  return 'new'
}

export const getInitialValues = dataModels => {
  const brandName = getBrandName()
  return {
    occupation: dataModels.occupationTypes[0].value,
    productId: '',
    downPaymentRate: 0,
    duration: 0,
    usedNew: getUsedNewValue(brandName),
    brand: ['vwfs', 'dwa', 'vwcv'].includes(brandName) ? undefined : brandName,
  }
}

export const resetData = (brand, setFieldValue, occupation = 'employee') => {
  const { storefront } = store.getState()
  const vehicleData =
    storefront?.data?.vehicleData ||
    storefront?.data?.dataStorage?.data?.vehicleData
  const usedCar =
    storefront?.data?.usedCar || storefront?.data?.dataStorage?.data?.usedCar
  const financialData =
    storefront?.data?.financialData ||
    storefront?.data?.dataStorage?.data?.financialData

  const retailPrice =
    store.getState().catalog.data.vehicleModel &&
    store.getState().catalog.data.vehicleModel.trimLine.trimLineDetail
      .retailPrice

  const vehicleBrand =
    brand || vehicleData?.brand || process.env.REACT_APP_WEBSITE_BRAND

  setFieldValue('brand', vehicleBrand)
  setFieldValue(
    'model',
    (vehicleData && (vehicleData.modelName || vehicleData.model)) || ''
  )
  setFieldValue('modelYear', (vehicleData && vehicleData.modelYear) || '')
  setFieldValue('version', (vehicleData && vehicleData.version) || '')
  setFieldValue('price', retailPrice || '')
  setFieldValue('duration', 0)
  setFieldValue(
    'downPaymentRate',
    financialData?.calculation?.downPaymentPercentage ?? 0
  )
  setFieldValue('productId', isMercadoLibre() ? '5' : '')
  store.dispatch(resetFinancialProducts())
  store.dispatch(resetPricing())
  store.dispatch(resetAmortization())
  if (!isEmpty(vehicleData)) {
    const used = usedCar ? 'used' : 'new'

    setFieldValue('usedNew', used)
    setFieldValue('brand', vehicleBrand)
    const isPfa = occupation === 'employee'
    loadFinancialDataToRedux(isPfa, vehicleBrand, used, vehicleData.modelYear)
      .then(financialProduct => {
        LoggerDebug('financialProduct', financialProduct)
        return financialProduct
      })

      .catch(error => {
        LoggerError('loadVehicleModelsToRedux', error)
        resetData(brand, setFieldValue)
      })
  }
}

export const maskNumber = value => {
  if (!value) return ''
  const parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const maskInputValue = (
  inputValue,
  setFieldValue,
  stateSetter,
  fieldName
) => {
  const numericValue = inputValue.replace(/[^0-9.]/g, '')
  let value = numericValue.replace(/,/g, '')
  if (value.length) {
    let decimalValue = '00'
    if (value.indexOf('.') !== -1) {
      if (value.split('.')[1].length === 1) {
        decimalValue = value.split('.')[1].concat('0')
      }
      if (value.split('.')[1].length === 2) {
        decimalValue = value.split('.')[1]
      }
      value = value.split('.')[0]
    }
    setFieldValue(fieldName, value + '.' + decimalValue)
  } else {
    setFieldValue(fieldName, '')
  }
  stateSetter(maskNumber(value))
}
