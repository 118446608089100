/**
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS environment variable we are able to
 * either export the real or mocked implementation, so that from the application
 * point of view nothing changes and it can continue calling the analytics logic
 * as per usuall.
 */
import merge from 'lodash/merge'
import { Logger } from '../common/logger'

const getBrowserResolutionBreakpointString = width => {
  if (width <= 480) return 'xs'
  if (width > 480 && width <= 720) return 's'
  if (width > 720 && width <= 960) return 'm'
  if (width > 960 && width <= 1280) return 'l'
  if (width > 1280 && width <= 1600) return 'xl'
  if (width > 1600) return 'xxl'
  return 'm'
}

const mock = {
  default: {},

  pageBottom: function pageBottom() {},

  setDefaults: function setDefaults(obj) {
    Object.assign(this.default, obj)
  },

  mergeDefaults: function mergeDefaults(obj) {
    Object.assign(this.default, merge({}, this.default, obj))
  },

  trackMultiple: function trackMultiple(obj) {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData.core.pageInfo.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
        },
      },
      event: obj.event,
    }
    const mergedData = merge({}, this.default, newStructure)
    window.du_digitalData = mergedData
  },

  track: function track(obj) {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData.core.pageInfo.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
          faqText: obj.faqText,
        },
      },
      error: obj.error,
      form: obj.form,
      design: {
        browserResolutionBreakpoint: obj.browserResolutionBreakpoint
          ? getBrowserResolutionBreakpointString(
              obj.browserResolutionBreakpoint
            )
          : null,
      },
      event: obj.events,
    }
    const mergedData = merge({}, this.default, newStructure)
    window.du_digitalData = mergedData
  },

  trackError: function track(obj) {
    const newStructure = {
      core: {
        pageInfo: {
          pageName:
            obj.pageName || window.du_digitalData.core.pageInfo.pageName,
        },
        attributes: {
          viewChange: obj.viewChange,
        },
      },
      event: [
        {
          eventInfo: {
            eventType: obj.eventType,
            eventAction: obj.eventAction,
          },
        },
      ],
      error: {
        errorCode: obj.errorCode,
        errorMessage: obj.errorMessage,
      },
    }
    const mergedData = merge({}, this.default, newStructure)
    window.du_digitalData = mergedData
  },

  viewChange: function viewChange() {
    window._satellite && window._satellite.track && window._satellite.track('viewChange') // eslint-disable-line
  },

  page: function page() {
    if (!implementation.isLive) {
      Logger('track page', window.du_digitalData)
    }
    window._satellite && window._satellite.track && window._satellite.track('page') // eslint-disable-line
  },

  interaction: function interaction() {
    if (!implementation.isLive) {
      Logger('track interaction', window.du_digitalData)
    }
    window._satellite && window._satellite.track && window._satellite.track('interaction') // eslint-disable-line
  },
}

const real = {
  ...mock,
  isLive: true,
  pageBottom: function pageBottom() {
    window._satellite && window._satellite.track && window._satellite.pageBottom() // eslint-disable-line
  },
}

let implementation = mock // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS) {
  implementation = real
}

export { implementation as Analytics }
