// import { usadosVehicleModelTrimLineId } from '../../data/utils'

import { LoggerDebug } from '../../common/logger'

/* eslint-disable no-underscore-dangle */
export const FETCH_CATALOG_PENDING = 'catalog//FETCH_CATALOG_PENDING'

export const FETCH_CATALOG_DONE = 'catalog//FETCH_CATALOG_DONE'

export const FETCH_CATALOG_ERROR = 'catalog//FETCH_CATALOG_ERROR'

export function pending() {
  return {
    type: FETCH_CATALOG_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_CATALOG_DONE,
    payload: {
      data,
    },
  }
}

export function getVehiclePrice(store) {
  return store?.catalog?.data?.vehicleModel?.trimLine?.trimLineDetail
    ?.retailPrice
}

export function error(err) {
  return {
    type: FETCH_CATALOG_ERROR,
    payload: {
      err,
    },
  }
}

function hasVehicleModels(store) {
  if (
    !store ||
    !store.catalog ||
    !store.catalog.data ||
    !(store.catalog.data.vehicleModels || store.catalog.data.vehicleModel)
  ) {
    return false
  }
  return true
}

function isUsedResponse(store) {
  if (
    store &&
    store.catalog &&
    store.catalog.data &&
    store.catalog.data?.brandVehicleModels
  ) {
    return true
  }
  return false
}

function isFromStorefront(store) {
  if (
    store &&
    store.catalog &&
    store.catalog.data &&
    store.catalog.data?.vehicleModel
  ) {
    return true
  }
  return false
}

const mapBrandName = {
  SEAT: 'seat',
  AUDI: 'audi',
  DUCATI: 'ducati',
  PORSCHE: 'porsche',
  VOLKSWAGEN: 'vw',
  VWCV: 'vw-lcv',
  DWA: 'dwa',
}

const mapBrand = brand => {
  try {
    switch (brand.toLowerCase()) {
      case 'volkswagen':
        return 'vw'
      case 'vwcv':
        return 'vw-lcv'
      default:
        return brand.toLowerCase()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`mapBrand error: ${e}`)
    return brand
  }
}

export function getVehicleModels(store, brand) {
  const list = Object.values(mapBrandName)
  if (!list.includes(brand) && !list.includes(mapBrand(brand))) {
    return null
  }
  if (isUsedResponse(store)) {
    const models = store.catalog.data.brandVehicleModels.find(it => {
      return (
        it.brandName.toLowerCase() === brand.toLowerCase() ||
        mapBrand(it.brandName.toLowerCase()) === brand.toLowerCase()
      )
    })
    return models?.vehicleModels || null
  }
  if (isFromStorefront(store)) {
    return store.catalog.data.vehicleModel
  }
  if (hasVehicleModels(store)) {
    return store.catalog.data.vehicleModels
  }
  return null
}

export function getModels(store, brand) {
  const vehicleModels = getVehicleModels(
    store,
    brand ?? process.env.REACT_APP_STOREFRONT_BRAND
  )
  if (!vehicleModels) return null
  if (Array.isArray(vehicleModels)) {
    return vehicleModels.map(it => it.name || it.vehicleModelName)
  }
  return [vehicleModels.name]
}

export function getMercadoLibreModel(store) {
  try {
    const vehicleModel =
      store.catalog.data?.brandVehicleModels[0]?.vehicleModels[0]?.trimLines[0]
        ?.trimLineDetails[0]
    const vehicleModelId = vehicleModel?.id ?? vehicleModel?.trimLineId
    LoggerDebug('getMercadoLibreModel', vehicleModelId)
    return vehicleModelId
  } catch (e) {
    try {
      // For ACS with Audi case, as catalog is little different:
      const vehicleModel =
        store.catalog.data?.vehicleModels[0]?.trimLines[0]?.trimLineDetails[0]
      const vehicleModelId = vehicleModel?.id ?? vehicleModel?.trimLineId
      LoggerDebug('getMercadoLibreModel', vehicleModelId)
      return vehicleModelId
    } catch (err) {
      return null
    }
  }
}

function findCarModel(vehicleModels, model) {
  return vehicleModels.find(
    it => it.name === model || it.vehicleModelName === model
  )
}

function findModelByYear(vehicleModel, modelYear) {
  return vehicleModel.trimLines.find(it => it.modelYear === modelYear)
}

function findModelByVersion(vehicleModel, version) {
  return vehicleModel.trimLineDetails.find(
    it => it.name === version || it.vehicleModelName === version
  )
}

export function getModelYears(store, brand, model) {
  const vehicleModels = getVehicleModels(
    store,
    brand ?? process.env.REACT_APP_STOREFRONT_BRAND
  )
  if (!vehicleModels) return null

  if (Array.isArray(vehicleModels)) {
    const vehicleModel = findCarModel(vehicleModels, model)
    if (vehicleModel) {
      return vehicleModel.trimLines
        .map(it => it.modelYear)
        .sort((a, b) => b - a)
    }
    return null
  }
  return [vehicleModels.trimLine.modelYear]
}

export function getVersions(store, brand, model, modelYear) {
  const vehicleModels = getVehicleModels(
    store,
    brand ?? process.env.REACT_APP_STOREFRONT_BRAND
  )
  if (!vehicleModels) return null
  if (Array.isArray(vehicleModels)) {
    const vehicleModel = findCarModel(vehicleModels, model)
    if (vehicleModel) {
      const vehicleModelByYear = findModelByYear(vehicleModel, modelYear)
      if (vehicleModelByYear) {
        return vehicleModelByYear.trimLineDetails.map(
          it => it.name || it.trimLineName
        )
      }
      return null
    }
    return null
  }
  return [vehicleModels.trimLine.trimLineDetail.name]
}

export function getPrice(
  store,
  brand,
  model,
  modelYear,
  version,
  usedNew,
  inputPrice = null
) {
  if (usedNew === 'used') {
    return !inputPrice || parseFloat(inputPrice, 10)
  }
  if (!brand || !model || !modelYear || !version) return null
  const catalogBrand =
    usedNew === 'used' && brand === 'vw' ? 'volkswagen' : brand
  const yearNumber = parseInt(modelYear, 10)
  const vehicleModels = getVehicleModels(
    store,
    catalogBrand ?? process.env.REACT_APP_STOREFRONT_BRAND
  )
  if (!vehicleModels) return null

  let storeVersion
  if (Array.isArray(vehicleModels)) {
    const vehicleModel = findCarModel(vehicleModels, model)
    if (vehicleModel) {
      const vehicleModelByYear = findModelByYear(vehicleModel, yearNumber)
      if (vehicleModelByYear) {
        storeVersion = findModelByVersion(vehicleModelByYear, version)
      }
    }
  } else {
    storeVersion = vehicleModels.trimLine.trimLineDetail
  }
  const price = storeVersion ? storeVersion.retailPrice : null

  return price
}

export function getTrimlineDetails(store, brand, model, modelYear, version) {
  const vehicleModels = getVehicleModels(
    store,
    brand ?? process.env.REACT_APP_STOREFRONT_BRAND
  )
  if (!vehicleModels || !brand || !model || !modelYear || !version) return null
  if (Array.isArray(vehicleModels)) {
    const vehicleModel = findCarModel(vehicleModels, model)
    if (vehicleModel) {
      const vehicleModelByYear = findModelByYear(vehicleModel, modelYear)
      if (vehicleModelByYear) {
        return findModelByVersion(vehicleModelByYear, version)
      }
      return null
    }
    return null
  }

  return vehicleModels.trimLine.trimLineDetail
}

export const getVehicleData = (
  store,
  usedNew,
  brand,
  model,
  modelYear,
  version,
  inputPrice
) => {
  const vehicleData = {}
  const catalogBrand =
    usedNew === 'used' && brand === 'vw' ? 'volkswagen' : brand
  vehicleData.models = catalogBrand ? getModels(store, catalogBrand) : []
  vehicleData.modelYears = model
    ? getModelYears(store, catalogBrand, model)
    : []
  vehicleData.versions = modelYear
    ? getVersions(store, catalogBrand, model, parseInt(modelYear, 10))
    : []
  vehicleData.price = getPrice(
    store,
    brand,
    model,
    modelYear,
    version,
    usedNew,
    inputPrice
  )
  return vehicleData
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATALOG_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_CATALOG_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_CATALOG_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
