export const brandsNew = [
  { value: '', label: '' },
  { value: 'vw', label: 'Volkswagen' },
  { value: 'seat', label: 'SEAT' },
  { value: 'audi', label: 'Audi' },
  { value: 'porsche', label: 'Porsche' },
  { value: 'vwcv', label: 'Vehículos Comerciales' },
]

export const brandsUsed = [
  { value: '', label: '' },
  { value: 'vw', label: 'Volkswagen' },
  { value: 'seat', label: 'SEAT' },
]

export const getLogosSrc = brandName => {
  switch (brandName) {
    case 'vwfs':
    case 'porsche':
      return 'porsche'
    case 'dwa':
      return 'dasweltauto-es'
    case 'vwcv':
      return 'commercial-vehicle-es'
    default:
      return undefined
  }
}
