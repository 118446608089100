/* eslint-disable no-underscore-dangle */
import { axios } from '..'
import { store } from '../../redux'
import * as actions from '../../redux/features/pricing.redux'
import * as actionsStorefront from '../../redux/features/storefront.redux'
import { getHitchObj } from '../../redux/features/financial-products.redux'
import {
  getMercadoLibreModel,
  getTrimlineDetails,
} from '../../redux/features/catalog.redux'
import { mapBrandUsedNew } from '../catalog/catalog.service'
import { getRequestBrand } from '../../../config'
import { checkNotNulls, isMercadoLibre } from '../../data/utils'
import { history, qs } from '../../routing'

const {
  REACT_APP_PROXY_API_KEY,
  REACT_APP_GET_SIMULATION_AMORTIZATION,
} = process.env

function getSimulationAmortizationData(
  brand,
  usedNew,
  isPfa,
  financialProductDurationId,
  vehicleModelTrimLineId,
  downPaymentPercentage,
  retailPrice,
  modelYear,
  productId
) {
  const cid = qs(document.location.search, 'cid')
  const isUsed = usedNew === 'used'
  const requestBrand = getRequestBrand(brand, usedNew)
  const url = `${REACT_APP_GET_SIMULATION_AMORTIZATION}/${requestBrand}/${isPfa}/create${
    cid ? `/${cid}` : ''
  }`
  const body = {
    isUsed,
    productId: Number(productId),
    financialProductDurationId,
    vehicleModelTrimLineId,
    downPaymentPercentage,
    retailPrice,
    ...(shouldIncludeModelYear(usedNew, modelYear)
      ? { modelYear: Number(modelYear) }
      : {}),
  }

  if (!checkNotNulls(body)) {
    return Promise.resolve({})
  }

  return axios
    .post(url, body, {
      headers: {
        'X-API-KEY': REACT_APP_PROXY_API_KEY,
      },
      validateStatus: statusCode => statusCode === 200,
    })
    .catch(e => {
      history.push('/error', {
        state: {
          code: null,
          message: `No pricing data ${e}`,
          url: window.location.href,
        },
      })
      throw e
    })
}

function internalLoadSimulationAmortizationToRedux(
  dispatch,
  brand,
  usedNew,
  isPfa,
  financialProductDurationId,
  vehicleModelTrimLineId,
  downPaymentPercentage,
  retailPrice,
  modelYear,
  productId
) {
  dispatch(actions.pending())

  return getSimulationAmortizationData(
    brand,
    usedNew,
    isPfa,
    financialProductDurationId,
    vehicleModelTrimLineId,
    downPaymentPercentage,
    retailPrice,
    modelYear,
    productId
  )
    .then(response => {
      const storefrontData = store.getState().storefront.data
      const data = response.data?.data ?? response.data ?? response

      dispatch(actionsStorefront.done(data))

      const cid = data?.dataStorage?.id
      if (cid) {
        history.push({
          search: `?cid=${cid}`,
        })
      }

      return {
        ...data,
        bookingAmount: storefrontData?.bookingData?.amount,
      }
    })
    .then(data => {
      return dispatch(actions.done(data))
    })
    .catch(e => {
      history.push('/error', {
        state: {
          code: null,
          message: `No financial data ${e}`,
          url: window.location.href,
        },
      })
    })
}

export function loadSimulationAmortizationToRedux(props, storefrontData) {
  const {
    brand,
    occupation,
    productId,
    duration,
    downPaymentRate,
    model,
    modelYear,
    version,
    usedNew,
    price,
  } = props

  let condition = 'new'
  if (typeof usedNew === 'string') {
    condition = usedNew
  } else if (usedNew) {
    condition = 'new'
  } else {
    condition = 'used'
  }

  if (
    isMercadoLibre() ||
    (brand &&
      occupation &&
      productId &&
      duration >= 0 &&
      downPaymentRate >= 0 &&
      model &&
      modelYear &&
      version &&
      (condition === 'new' || price))
  ) {
    const data = enrichPricingRequestData(props)
    let vehicleModelTrimLineId = null
    let retailPrice = parseFloat(price, 10)
    if (!data) return new Promise(() => null)
    const catalogBrand = mapBrandUsedNew(brand, condition)
    if (isMercadoLibre()) {
      vehicleModelTrimLineId = getMercadoLibreModel(store.getState())
    } else if (usedNew === 'used' && brand === 'audi') {
      vehicleModelTrimLineId =
        store.getState().storefront?.data?.vehicleData?.versionId ||
        storefrontData?.dataStorage?.data?.vehicleData?.versionId
    } else {
      const trimlineDetails = getTrimlineDetails(
        store.getState(),
        catalogBrand,
        model,
        parseInt(modelYear, 10),
        version
      )
      if (trimlineDetails) {
        retailPrice =
          condition === 'used'
            ? parseFloat(price, 10)
            : trimlineDetails.retailPrice
        vehicleModelTrimLineId =
          trimlineDetails.id ?? trimlineDetails.trimLineId
      }
    }

    return internalLoadSimulationAmortizationToRedux(
      store.dispatch,
      brand,
      condition,
      data.isPfa,
      data.financialProductDurationId,
      vehicleModelTrimLineId,
      data.downPaymentPercentage || 0.25,
      retailPrice,
      modelYear,
      productId
    )
  } else {
    return new Promise(() => null)
  }
}

export function enrichPricingRequestData(props) {
  const { occupation, productId, duration, downPaymentRate } = props
  const isPfa = occupation === 'employee'
  const hitchObj = getHitchObj(
    store.getState(),
    parseInt(productId, 10),
    duration,
    isPfa
  )
  if (!hitchObj) {
    return null
  }
  const usedNew = props.usedNew === 'new' ? 'new' : 'used'
  const { id, interestRate } = hitchObj
  const downPaymentPercentage = downPaymentRate / 100 || 0.25
  return {
    isPfa,
    financialProductDurationId: id,
    downPaymentPercentage,
    interestRate,
    ...props,
    downPaymentRate: downPaymentRate || 0.25,
    duration: duration || 24,
    usedNew,
  }
}

function shouldIncludeModelYear(usedNew, modelYear) {
  return usedNew === 'used' && modelYear
}
