import React from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Logo, ContextBar } from '@vwfs-bronson/bronson-react'
import { getBrandName } from '../../config'

import ContactPageModal from '../ContactPageModal'
import Footer from './footer'
import { Analytics } from '../../services/analytics'
import { getLogosSrc } from '../../resources/base/models/brands'

const DefaultLayout = ({ pages, children }) => {
  const displayName =
    children.type && children.type.WrappedComponent
      ? children.type.WrappedComponent.name
      : null
  const { t } = useTranslation()
  const [showContact, setShowContact] = React.useState(false)

  React.useEffect(() => {
    // When user gets back from eSign analytics isn't initializing correctly
    // this has to be investigated, currently window.du_digitalData is undefined
    if (showContact && window.du_digitalData) {
      Analytics.track({
        pageName: displayName,
        viewChange: 'Contact Dialog',
        events: [
          {
            eventInfo: {
              eventType: 'pageView',
              eventAction: 'Success',
            },
          },
        ],
        browserResolutionBreakpoint: window.innerWidth,
      })
      Analytics.interaction()
    }
  }, [showContact, displayName])

  return (
    <>
      <Header
        number={1}
        staticHeader
        logo={
          <Logo specificLogo={getLogosSrc(getBrandName())} breakpoint="720px" />
        }
      >
        <ContextBar key={2}>
          <ContextBar.SiteNav>
            <ContextBar.SiteNav.Item title noLink />
          </ContextBar.SiteNav>
          <ContextBar.SiteNav>
            <ContextBar.SiteNav.Item contextLabel="ContactIcon">
              <li>
                <button
                  type="button"
                  className="c-btn  c-btn--link c-context-bar__link"
                  onClick={() => setShowContact(true)}
                >
                  <i className="c-icon  c-icon--[semantic-chat]  c-context-bar__icon" />
                  <span className="c-context-bar__icon-label">
                    {t('header:actionHeading')}
                  </span>
                </button>
              </li>
            </ContextBar.SiteNav.Item>
          </ContextBar.SiteNav>
        </ContextBar>
      </Header>

      <ContactPageModal
        visible={showContact}
        onClose={() => setShowContact(false)}
      />

      <main className="o-main">{children}</main>
      <Footer pages={pages} />
    </>
  )
}

export default DefaultLayout
