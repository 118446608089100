export const FETCH_STATES_PENDING = 'states//FETCH_STATES_PENDING'

export const FETCH_STATES_DONE = 'states//FETCH_STATES_DONE'

export const FETCH_STATES_ERROR = 'states//FETCH_STATES_ERROR'

export const RESET_STATES = 'states//RESET_STATES'

export function pending() {
  return {
    type: FETCH_STATES_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_STATES_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_STATES_ERROR,
    payload: {
      err,
    },
  }
}

export function reset() {
  return {
    type: RESET_STATES,
  }
}

export function getStates(store) {
  return store.states.data
}

export function getStateId(store, stateName) {
  if (!getStates(store) || !stateName) return null
  const state = getStates(store).find(it => it.stateName === stateName)
  return state && state.stateId ? state.stateId : null
}

const initialState = {
  pending: false,
  data: [],
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATES_PENDING:
      return {
        ...state,
        data: [],
        pending: true,
        error: null,
      }

    case FETCH_STATES_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_STATES_ERROR:
      return {
        ...state,
        data: [],
        pending: false,
        error: true,
      }
    case RESET_STATES:
      return initialState

    default:
      return state
  }
}
