import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SectionHeading,
  Layout,
  RadioButtonGroup,
  RadioButton,
  Spinner,
  Input,
  Paragraph,
  Fieldset,
  FormField,
} from '@vwfs-bronson/bronson-react'
import { ContentSection } from '../../../components/ContentSection'
import { arrayToValueLabel } from '../../../services/common/form'
import { formatNumber } from '../../../services/common/format'
import { loadVehicleModelsToRedux } from '../../../services/api/catalog/catalog.service'
import { loadFinancialDataToRedux } from '../../../services/api/catalog/financial-products.service'
import { Dropdown } from '../../../components/Dropdown/Dropdown'
import {
  getBrandName,
  shouldShowLegend,
  CURRENCY_SYMBOL,
} from '../../../config'
import { LoggerError } from '../../../services/common/logger'
import { maskInputValue } from '../field-definitions'

const UsedNew = ({
  t,
  values,
  dataModels,
  handleBrandChange,
  setBrands,
  setFieldValue,
}) => {
  return (
    <FormField name="usedNew">
      <RadioButtonGroup>
        <RadioButton
          value="new"
          checked={values && values.usedNew === 'new'}
          onChange={() => {
            handleBrandChange('')
            setBrands(dataModels.brandsNew)
            setFieldValue('usedNew', 'new')
          }}
        >
          {t('calculation:vehicleSelect:new')}
        </RadioButton>
        <RadioButton
          value="used"
          checked={values && values.usedNew === 'used'}
          onChange={() => {
            handleBrandChange('')
            setBrands(dataModels.brandsUsed)
            setFieldValue('usedNew', 'used')
          }}
        >
          {t('calculation:vehicleSelect:used')}
        </RadioButton>
      </RadioButtonGroup>
    </FormField>
  )
}

const Brand = ({ t, values, brands, handleBrandChange, formProps }) => {
  return (
    <Dropdown
      name="brand"
      labelForId="brand"
      labelText={t('calculation:vehicleSelect:labels:brand')}
      values={values.brand}
      formProps={formProps}
      onChange={brand => handleBrandChange(brand.currentTarget.value)}
      disabled={values && !values.usedNew}
      list={[brands[0], ...brands.slice(1).reverse()]}
    />
  )
}

const Model = ({ t, values, vehicleData, setModel, formProps }) => {
  if (!values.brand && !vehicleData.models) {
    return <Spinner small={false} center />
  }
  return (
    <Dropdown
      name="model"
      labelForId="model"
      labelText={t('calculation:vehicleSelect:labels:model')}
      values={arrayToValueLabel(vehicleData?.models)}
      formProps={formProps}
      onChange={model => setModel(model?.currentTarget?.value)}
      disabled={
        !values?.brand || !vehicleData?.models || !vehicleData?.models.length
      }
      list={vehicleData?.models}
    />
  )
}

const ModelYear = ({ t, values, vehicleData, setModelYear, formProps }) => (
  <Dropdown
    name="modelYear"
    labelForId="modelYear"
    labelText={t('calculation:vehicleSelect:labels:modelYear')}
    values={arrayToValueLabel(vehicleData.modelYears)}
    formProps={formProps}
    onChange={modelYear => setModelYear(modelYear.currentTarget.value)}
    disabled={
      !values.model || !vehicleData.modelYears || !vehicleData.modelYears.length
    }
    list={vehicleData.modelYears}
  />
)

const Version = ({
  t,
  values,
  vehicleData,
  setVersion,
  setFieldValue,
  resetData,
  formProps,
}) => (
  <Dropdown
    name="version"
    labelForId="version"
    labelText={t('calculation:vehicleSelect:labels:version')}
    values={
      vehicleData?.versions?.length
        ? arrayToValueLabel(vehicleData?.versions)
        : []
    }
    formProps={formProps}
    onChange={version => {
      setFieldValue('productId', null)
      setVersion(version.currentTarget.value)
      loadFinancialDataToRedux(
        values?.occupation === 'employee',
        values?.brand,
        values?.usedNew,
        values?.modelYear
      ).catch(error => {
        LoggerError('loadFinancialDataToRedux', error)
        resetData(values?.brand, setFieldValue)
      })
    }}
    disabled={
      !values?.modelYear ||
      !vehicleData?.versions ||
      !vehicleData?.versions?.length
    }
    list={vehicleData?.versions}
  />
)

let clearPrice = () => {}

const PriceInput = ({
  values,
  setFieldValue,
  priceUpdateCallback,
  name,
  formProps,
}) => {
  const [priceValue, setPriceValue] = useState('')
  return (
    <Input
      name={name}
      testId={name}
      type="text"
      addonText={CURRENCY_SYMBOL}
      reversed
      disabled={!values.version || !values.version.length}
      onChange={e => {
        maskInputValue(e.target.value, setFieldValue, setPriceValue, name)
        priceUpdateCallback(e.target.value)
      }}
      value={priceValue}
      formProps={formProps}
    />
  )
}

const Price = ({
  t,
  values,
  setFieldValue,
  vehicleData,
  priceUpdateCallback,
  formProps,
}) => {
  const formattedPrice = vehicleData.price
    ? formatNumber(parseFloat(vehicleData.price).toFixed(2))
    : ''
  if (values && values.usedNew === 'new') {
    priceUpdateCallback(formattedPrice)
  }
  return (
    <FormField
      name="price"
      notion={shouldShowLegend(values.usedNew) ? true : false}
      labelText={t('calculation:vehicleSelect:labels:price')}
    >
      {values && values.usedNew === 'used' ? (
        <PriceInput
          name="price"
          values={values}
          setFieldValue={setFieldValue}
          notion
          priceUpdateCallback={priceUpdateCallback}
          formProps={formProps}
        />
      ) : (
        <Input
          addonText={CURRENCY_SYMBOL}
          reversed
          disabled
          value={formattedPrice || ''}
          formProps={formProps}
        />
      )}
      {shouldShowLegend(values.usedNew) && (
        <Paragraph className="u-font-size-fs-2 u-mt-xsmall">
          {t('calculation:vehicleSelect:priceDisclaimer')}
        </Paragraph>
      )}
    </FormField>
  )
}

const VehicleSelection = props => {
  const [brands, setBrands] = React.useState([])
  const {
    dataModels,
    values,
    setFieldValue,
    vehicleData,
    resetData,
    priceUpdateCallback,
    formProps,
  } = props

  const { t } = useTranslation()

  const handleBrandChange = brand => {
    const isPfa = values.occupation === 'employee'
    const used = values.usedNew === 'new' ? 'new' : 'used'
    resetData(brand, setFieldValue)

    if (brand) {
      loadVehicleModelsToRedux(isPfa, brand, used)
    }
  }

  const setModel = model => {
    setModelYear('')
    setFieldValue('model', model)
  }

  const setModelYear = modelYear => {
    setVersion('')
    setFieldValue('modelYear', modelYear)
  }

  const setVersion = version => {
    clearPrice()
    setFieldValue('version', version)
  }

  React.useEffect(() => {
    if (values.usedNew) {
      setBrands(
        values.usedNew === 'new' ? dataModels.brandsNew : dataModels.brandsUsed
      )
    }
    if (values.brand) {
      handleBrandChange(values.brand)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const brandName = getBrandName()

  return (
    <ContentSection>
      <SectionHeading number={5}>
        {t('calculation:vehicleSelect:heading')}
      </SectionHeading>
      <Fieldset>
        {['vwfs'].includes(brandName) && (
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/1" s="1/1">
                <UsedNew
                  t={t}
                  values={values}
                  dataModels={dataModels}
                  handleBrandChange={handleBrandChange}
                  setBrands={setBrands}
                  setFieldValue={setFieldValue}
                  resetData={resetData}
                />
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        )}
        <Fieldset.Row>
          <Layout>
            {['vwfs', 'dwa'].includes(brandName) && (
              <Layout.Item default="1/2" s="1/1">
                <Brand
                  t={t}
                  values={values}
                  formProps={formProps}
                  brands={brands}
                  handleBrandChange={handleBrandChange}
                />
              </Layout.Item>
            )}
            <Layout.Item default="1/2" s="1/1">
              <Model
                t={t}
                values={values}
                formProps={formProps}
                vehicleData={vehicleData}
                setModel={setModel}
              />
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">
              <ModelYear
                t={t}
                values={values}
                formProps={formProps}
                vehicleData={vehicleData}
                setModelYear={setModelYear}
              />
            </Layout.Item>
            <Layout.Item default="1/2" s="1/1">
              <Version
                t={t}
                values={values}
                formProps={formProps}
                vehicleData={vehicleData}
                setVersion={setVersion}
                setFieldValue={setFieldValue}
                resetData={resetData}
              />
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">
              <Price
                t={t}
                values={values}
                formProps={formProps}
                setFieldValue={setFieldValue}
                vehicleData={vehicleData}
                priceUpdateCallback={priceUpdateCallback}
              />
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
      </Fieldset>
    </ContentSection>
  )
}

export default VehicleSelection
