/* eslint-disable prefer-destructuring */
const FULL_LOG = false

export const Logger = (msg, ...other) => {
  const stage = window.location.host.split('.')[0]
  if (['dev', 'int', 'cons'].indexOf(stage) !== -1) {
    if (!FULL_LOG) return
    // eslint-disable-next-line no-console
    console.log(msg, ...other)
  }
}

export const LoggerDebug = (msg, ...other) => {
  const stage = window.location.host.split('.')[0]
  if (['dev', 'int', 'cons'].indexOf(stage) !== -1) {
    if (!FULL_LOG) return
    // eslint-disable-next-line no-console
    console.debug(msg, ...other)
  }
}

export const LoggerError = (msg, ...other) => {
  // eslint-disable-next-line no-console
  console.error(msg, ...other)
}
