import _axios from 'axios'
import { Logger, LoggerError } from '../common/logger'

export const axios = _axios.create({})

// axios.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.timeout = 10000

axios.interceptors.request.use(
  config => {
    Logger(`config ${config.url}`)
    return config
  },
  error => {
    LoggerError(error)
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    Logger(response)
    // Edit response config
    return response?.data?.body ?? response?.body ?? response?.data ?? response
  },
  error => {
    LoggerError(error)
    return Promise.reject(error)
  }
)
