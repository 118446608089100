/* eslint-disable react/display-name */
import React from 'react'

export const DefinitionList = ({ children }) => (
  <dl className="c-dl-horizontal  c-dl-horizontal--66-to-33">{children}</dl>
)

DefinitionList.Title = ({ children }) => (
  <dt className="c-dl-horizontal__title">{children}</dt>
)

DefinitionList.Item = ({ children }) => (
  <dd className="c-dl-horizontal__item">{children}</dd>
)
