// @ts-nocheck

import React from 'react'
import { connect } from 'react-redux'
// @ts-ignore
import { Switch, Route, Redirect } from 'react-router-dom'
import { Spinner } from '@vwfs-bronson/bronson-react'

import { setDataModels } from './services/redux/features/data-models.redux'
import {
  setDealerId,
  setDealerData,
} from './services/redux/features/dealers.redux'
import { getBrandName } from './config'
import basePages from './resources/base/pages'
import * as baseModels from './resources/base/models'
import CalculationPage from './screens/CalculationPage'
import DefaultLayout from './screens/DefaultLayout'
import { history, qs } from './services/routing'
import WithStorefront from './services/api/storefront/with-storefront'
import {
  getDealerBrandCodeData,
  getDealerData as getDealerDataOld,
} from './services/api/dealer/dealer.service'
import { isMercadoLibre, isACS, isESignCompletion } from './services/data/utils'
import { LoggerError } from './services/common/logger'

const ErrorPageLazy = React.lazy(() => import('./screens/ErrorPage'))
// @ts-ignore
const AppFormLazy = React.lazy(() => import('./screens/AppForm'))
// @ts-ignore
const CreditCheckLazy = React.lazy(() => import('./screens/CreditCheck'))
const ConfirmationPageLazy = React.lazy(() =>
  // @ts-ignore
  import('./screens/CompletionPages/ConfirmationPage')
)
const RejectionPageLazy = React.lazy(() =>
  // @ts-ignore
  import('./screens/CompletionPages/RejectionPage')
)
const FAQPageLazy = React.lazy(() => import('./screens/FAQPage'))

const Lazy = props => {
  const { page, ...componentProps } = props
  const brand = getBrandName()
  const [component, setComponent] = React.useState(null)
  if (page === '' || !brand) {
    return null
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useLayoutEffect(() => {
    setComponent(null)
    // @ts-ignore
    setComponent(() =>
      React.lazy(() => import(`./resources/${brand}/pages/${page}.js`))
    )
  }, [page, brand])

  if (component) {
    return React.createElement(component, componentProps)
  }
  return null
}

function App(props) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Develop')
  } else {
    // eslint-disable-next-line no-console, func-names
    console.log = function() {}
    // eslint-disable-next-line no-console, func-names
    console.debug = function() {}
  }
  const pages = basePages

  const { setDataModels: setModels } = props

  React.useEffect(() => {
    setModels({ ...baseModels })
  }, [props, setModels])

  function getPageFile(pageName) {
    if (!pages.length) {
      return ''
    }
    const page = pages.find(p => p.url === pageName)

    if (!page) {
      history.push('/')
      // eslint-disable-next-line consistent-return
      return ''
    }

    // eslint-disable-next-line consistent-return
    return page.file || ''
  }
  sessionStorage.clear()
  // const prefill = process.env.NODE_ENV === 'development' ? '/:prefill?' : '/'
  const storefrontId = qs(document.location.search, 'cid')

  if (storefrontId) {
    if (isMercadoLibre()) {
      sessionStorage.removeItem('storefrontId')
    } else {
      sessionStorage.setItem('storefrontId', storefrontId)
      if (process.env.REACT_APP_WEBSITE_BRAND === 'porsche') {
        sessionStorage.setItem('porscheLead', 'true')
      } else {
        sessionStorage.setItem('porscheLead', 'false')
      }
    }
  }

  if (isMercadoLibre() && !storefrontId) {
    history.push('/error')
  }

  const dealeridQuery = qs(document.location.search, 'dealerid')
  const dealerIdQuery = qs(document.location.search, 'dealerId')
  const dealerid = dealeridQuery || dealerIdQuery

  React.useEffect(() => {
    if (dealerid) {
      // eslint-disable-next-line react/destructuring-assignment
      props.setDealerId(dealerid)
      getDealerBrandCodeData(dealerid)
        .then(result => {
          // eslint-disable-next-line react/destructuring-assignment
          props.setDealerData(result?.data ?? result)
        })
        .catch(err => {
          LoggerError(`Error fetching dealerid data: ${err}`)
          getDealerDataOld(dealerid).then(result => {
            // eslint-disable-next-line react/destructuring-assignment
            props.setDealerData(result?.data ?? result)
          })
        })
    }
  })

  const addOtherRoutes = () => {
    const otherRoutes = [
      <Route
        exact
        path="/form"
        key="/form"
        render={() => {
          return (
            <DefaultLayout pages={pages}>
              <AppFormLazy />
            </DefaultLayout>
          )
        }}
      />,
      <Route
        exact
        path="/creditcheck"
        key="/creditcheck"
        render={() => {
          return (
            <DefaultLayout pages={pages}>
              <CreditCheckLazy />
            </DefaultLayout>
          )
        }}
      />,
      <Route
        path="/confirmation"
        key="/confirmation"
        render={() => (
          <WithStorefront storefrontId={storefrontId}>
            <DefaultLayout pages={pages}>
              <ConfirmationPageLazy />
            </DefaultLayout>
          </WithStorefront>
        )}
      />,
      <Route
        path="/confirmation2"
        key="/confirmation2"
        render={() => (
          <WithStorefront storefrontId={storefrontId}>
            <DefaultLayout pages={pages}>
              <RejectionPageLazy />
            </DefaultLayout>
          </WithStorefront>
        )}
      />,
      <Route
        path="/faq"
        key="/faq"
        render={() => (
          <DefaultLayout pages={pages}>
            <FAQPageLazy />
          </DefaultLayout>
        )}
      />,
      <Route
        path="/error"
        key="/error"
        render={routeProps => {
          return (
            <DefaultLayout pages={pages}>
              <ErrorPageLazy {...routeProps} />
            </DefaultLayout>
          )
        }}
      />,
      <Route
        path="/page/:pageName"
        key="/page/:pageName"
        render={routeProps => (
          <Lazy
            {...routeProps}
            page={getPageFile(routeProps.match.params.pageName)}
            brand={getBrandName()}
          />
        )}
      />,
    ]
    return otherRoutes
  }
  const renderCalculationPage = used => {
    if (storefrontId) {
      return (
        <DefaultLayout pages={pages}>
          <WithStorefront storefrontId={storefrontId} used={used}>
            <CalculationPage storefrontId={storefrontId} />
          </WithStorefront>
        </DefaultLayout>
      )
    }
    return (
      <DefaultLayout pages={pages}>
        <CalculationPage />
      </DefaultLayout>
    )
  }

  const addEntryRoutes = () => {
    // For Audi, if a storefrontId is present, it is a used car.
    // For VWFS, it is decided based on the url path (/usados - means used)
    const isBasePathForUsedCars =
      process.env.REACT_APP_WEBSITE_BRAND === 'audi' && storefrontId

    const entryRoutesForOtherBrands = [
      <Route
        key="/"
        exact
        path="/"
        render={() => renderCalculationPage(isBasePathForUsedCars)}
      />,
    ]

    const entryRouteForESignCompletion = [
      <Route
        exact
        path="/esign"
        key="/esign"
        render={() => {
          return (
            <DefaultLayout pages={pages}>
              <CreditCheckLazy />
            </DefaultLayout>
          )
        }}
      />,
    ]

    const entryRouteForACS = [
      <Route
        exact
        path="/usados/acs"
        key="/acs"
        render={() => renderCalculationPage(true)}
      />,
    ]

    const entryRoutesForVWFS = [
      <Route
        path="/usados"
        key="/usados"
        render={() => renderCalculationPage(true)}
      />,
      <Route
        exact
        path="/"
        key="/"
        render={() => renderCalculationPage(false)}
      />,
    ]

    if (isESignCompletion()) {
      return entryRouteForESignCompletion
    }

    if (process.env.REACT_APP_WEBSITE_BRAND === 'vwfs' && !isACS()) {
      return entryRoutesForVWFS
    }

    if (process.env.REACT_APP_WEBSITE_BRAND === 'vwfs' && isACS()) {
      return entryRouteForACS
    }

    return entryRoutesForOtherBrands
  }

  return (
    <>
      <React.Suspense fallback={<Spinner small={false} fullPage />}>
        <Switch>
          {addEntryRoutes()}
          {addOtherRoutes()}
          <Redirect
            from="*"
            to={{
              pathname: '/error',
              state: { code: '404' },
            }}
          />
        </Switch>
      </React.Suspense>
    </>
  )
}

export default connect(undefined, {
  setDataModels,
  setDealerId,
  setDealerData,
})(App)
