/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  Spinner,
  Table,
  Footnote,
} from '@vwfs-bronson/bronson-react'
import { ContentSection } from '../../../components/ContentSection'
import { formatCurrency } from '../../../services/common/format'

const AmortizationTable = ({
  isLoadingAmortizationTable,
  amortizationRendered,
  pricingData,
}) => {
  const amortizationTable = pricingData?.amortization?.amortizationTable
  const { t } = useTranslation()
  const COLUMNS = [
    'month',
    'payment',
    'interest',
    'iva',
    'amortization',
    'outstandingBalance',
  ]
  amortizationRendered(!isLoadingAmortizationTable)
  const FIELDNAMES = [
    'installmentAmount',
    'interestAmount',
    'interestIvaAmount',
    'amortizationAmount',
    'remainingLoanAmount',
  ]
  const sum = {
    installmentAmount: 0,
    interestAmount: 0,
    interestIvaAmount: 0,
    amortizationAmount: 0,
  }
  const sumField = (fieldName, value) => {
    if (Object.prototype.hasOwnProperty.call(sum, fieldName)) {
      sum[fieldName] += parseFloat(value)
    }
    return true
  }

  return (
    <ContentSection>
      {isLoadingAmortizationTable && <Spinner small={false} center />}
      {!isLoadingAmortizationTable &&
        amortizationTable &&
        amortizationTable.length && (
          <article className="c-card  u-mt" data-theme="alternative">
            <div className="c-card__body">
              <Accordion>
                <Accordion.Item
                  title={t('calculation:amortizationTable:heading')}
                >
                  <React.Fragment>
                    <div>
                      <Table>
                        <Table.Thead>
                          <Table.Tr>
                            {COLUMNS.map(col => (
                              <Table.Th key={col}>
                                {t(
                                  `calculation:amortizationTable:columns:${col}`
                                )}
                              </Table.Th>
                            ))}
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {amortizationTable.map((item, index) => {
                            return (
                              <Table.Tr key={index}>
                                <Table.Td>{index}</Table.Td>
                                {FIELDNAMES.map(fieldName => (
                                  <Table.Td key={fieldName}>
                                    {Object.prototype.hasOwnProperty.call(
                                      item,
                                      fieldName
                                    ) &&
                                      item[fieldName] &&
                                      sumField(
                                        fieldName,
                                        item[fieldName].toFixed(2)
                                      ) &&
                                      formatCurrency(
                                        item[fieldName].toFixed(2),
                                        'symbol'
                                      )}
                                  </Table.Td>
                                ))}
                              </Table.Tr>
                            )
                          })}
                          <Table.Tr>
                            <Table.Td>
                              <strong>
                                {t('calculation:amortizationTable:sum')}
                              </strong>
                            </Table.Td>
                            {FIELDNAMES.map(
                              fieldName =>
                                Object.prototype.hasOwnProperty.call(
                                  sum,
                                  fieldName
                                ) && (
                                  <Table.Td key={fieldName}>
                                    <strong>
                                      {formatCurrency(
                                        sum[fieldName].toFixed(2),
                                        'symbol'
                                      )}
                                    </strong>
                                  </Table.Td>
                                )
                            )}
                            <Table.Td />
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                    </div>
                    <hr />
                    <div>
                      <Footnote>
                        {t('calculation:amortizationTable:footer1')}
                      </Footnote>
                    </div>
                    <br />
                  </React.Fragment>
                </Accordion.Item>
              </Accordion>
            </div>
          </article>
        )}
    </ContentSection>
  )
}

export default AmortizationTable
