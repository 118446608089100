/**
 * For now only localStorage is provided. In future, this functionality
 * can be extended to - for example - support sroting user data on a server.
 */

const localStorageEngine = {
  get: key => {
    return Promise.resolve(JSON.parse(window.localStorage.getItem(key)))
  },

  set: (key, value) => {
    return Promise.resolve(
      window.localStorage.setItem(key, JSON.stringify(value))
    )
  },
}

export function Storage(storageEngine = localStorageEngine) {
  return storageEngine
}
