export const RESET_CALCULATION_DATA = 'calculation//RESET_CALCULATION_DATA'

export const SAVE_CALCULATION_DATA = 'calculation//SAVE_CALCULATION_DATA'

export function saveCalculationData(data) {
  return {
    type: SAVE_CALCULATION_DATA,
    payload: data,
  }
}

export function resetCalculationData() {
  return {
    type: RESET_CALCULATION_DATA,
  }
}

export function getCalculationData(store) {
  return store.calculation.data
}

const initialState = {
  data: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_CALCULATION_DATA:
      return {
        ...state,
        data: {},
      }

    case SAVE_CALCULATION_DATA:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
