const pages = [
  {
    name: 'Aviso de Privacidad',
    url: 'https://www.vwfs.mx/vwl/aviso-de-privacidad/clientes-prospectos.html',
    target: '_blank',
    key: '0001',
  },
  {
    name: 'Términos y Condiciones',
    url: 'https://www.vwfs.mx/es/vwl/terminos-condiciones-cotizador.html',
    target: '_blank',
    key: '0002',
  },
  {
    name: 'Costos y Comisiones',
    url: 'https://www.vwfs.mx/vwl/costos-comisiones.html',
    target: '_blank',
    key: '0003',
  },
  {
    name: 'Preguntas Frecuentes',
    url: '{env.PUBLIC_URL}/faq',
    target: '_blank',
    key: '0004',
  },
]

export default pages
