import React from 'react'
import { useTranslation } from 'react-i18next'
import { Footer as BRFooter, BackToTop } from '@vwfs-bronson/bronson-react'

import { evaluateEnvironmentVariables } from '../../services/common/format'

const Footer = ({ pages }) => {
  const { t } = useTranslation()

  return (
    <>
      <BackToTop>{t('footer:backToTopLabel')}</BackToTop>
      <BRFooter>
        <BRFooter.ModuleMetaNav copyrightText={t('footer:copyright')}>
          {pages.map(page => (
            <BRFooter.ModuleMetaNav.Item
              href={evaluateEnvironmentVariables(page.url)}
              linkProps={{ target: page.target }}
              normalLink
              key={`${page.key}`}
            >
              {page.name}
            </BRFooter.ModuleMetaNav.Item>
          ))}
        </BRFooter.ModuleMetaNav>
      </BRFooter>
    </>
  )
}

export default Footer
