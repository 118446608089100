import React from 'react'

export const scrollToElement = (selector, align = 'start') => {
  const element = document.querySelector(selector)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: align,
      inline: 'center',
    })
  }
}

export const scrollToActiveFormSection = step => {
  scrollToElement(`#${step}`, 'center')
}

export const isValidationDisabled = property => {
  return property.className.indexOf('disabled-validation') !== -1
}

export const sortDataModelJson = json => {
  return json.sort((a, b) => {
    if (
      b.value === '' ||
      a.value === '' ||
      a.value === 'MX' ||
      b.value === 'MX'
    ) {
      return 0
      // eslint-disable-next-line no-else-return
    } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1
    } else if (b.label.toLowerCase() > a.label.toLowerCase()) {
      return -1
    }
    return 0
  })
}

export const addLinebreak = text => {
  if (text && text.length) {
    return (
      <>
        {text}
        <br />
      </>
    )
  }
  return null
}

export const arrayToValueLabel = arr => {
  let returnValue = [{ value: '', label: '' }]
  if (arr && arr.length > 0) {
    const obj = arr.map(item => {
      return { value: item.toString(), label: item.toString() }
    })
    obj.unshift(returnValue[0])
    returnValue = obj
  }
  return returnValue
}
