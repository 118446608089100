import { Logger, LoggerDebug } from '../../common/logger'
import { isMercadoLibre, isACS, isObject } from '../../data/utils'

/* eslint-disable no-underscore-dangle */
export const FETCH_FINANCIAL_PRODUCT_PENDING =
  'financialProducts//FETCH_FINANCIAL_PRODUCT_PENDING'

export const FETCH_FINANCIAL_PRODUCT_DONE =
  'financialProducts//FETCH_FINANCIAL_PRODUCT_DONE'

export const FETCH_FINANCIAL_PRODUCT_ERROR =
  'financialProducts//FETCH_FINANCIAL_PRODUCT_ERROR'

export const RESET_FINANCIAL_PRODUCT =
  'financialProducts//RESET_FINANCIAL_PRODUCT'

const VALID_PRODUCT_IDS = [1, 2]

export function pending() {
  return {
    type: FETCH_FINANCIAL_PRODUCT_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_FINANCIAL_PRODUCT_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_FINANCIAL_PRODUCT_ERROR,
    payload: {
      err,
    },
  }
}

export function reset() {
  return {
    type: RESET_FINANCIAL_PRODUCT,
  }
}

function hasFinancialProducts(store) {
  return (
    store &&
    store.financialProduct &&
    store.financialProduct.data &&
    store.financialProduct.data.financialProducts &&
    store.financialProduct.data.financialProducts.length
  )
}

export function getDurations(store, productId, downPayment, isPfa) {
  let { financialProducts } = store.financialProduct?.data || {
    financialProducts: [],
  }
  financialProducts = getFinancialProductsStore(isPfa, financialProducts, store)
  if (
    (!hasFinancialProducts(store) &&
      (!financialProducts || !financialProducts.length)) ||
    !productId
  ) {
    return null
  }
  try {
    const products = financialProducts.find(it => it.id === productId)
    return (
      !products ||
      products.productDurations
        .filter(
          it =>
            it.minimumDownPayment <= downPayment &&
            it.maximumDownPayment >= downPayment
        )
        .map(it => it.duration)
        .sort()
    )
  } catch (e) {
    LoggerDebug('getDurations: No available products', e)
    return null
  }
}

export function getHitchObj(store, productId, duration, isPfa) {
  let { financialProducts } = store.financialProduct?.data || {
    financialProducts: [],
  }
  financialProducts = getFinancialProductsStore(isPfa, financialProducts, store)

  if (
    (!hasFinancialProducts(store) &&
      (!financialProducts || !financialProducts.length)) ||
    !productId
  ) {
    return null
  }
  try {
    const products = financialProducts.find(it => it.id === productId)
    const product =
      !products ||
      products.productDurations.find(it => it.duration === (duration || 24))
    return product
  } catch (e) {
    LoggerDebug('getHitchObj: No available products', e)
    return null
  }
}

export function getHitch(store, productId, isPfa) {
  let { financialProducts } = store.financialProduct?.data || {
    financialProducts: [],
  }
  financialProducts = getFinancialProductsStore(isPfa, financialProducts, store)

  if (
    (!hasFinancialProducts(store) &&
      (!financialProducts || !financialProducts.length)) ||
    !productId
  ) {
    return null
  }
  try {
    const products = financialProducts.find(it => it.id === productId)
    if (!products) return null
    const min = Math.min(
      ...products.productDurations.map(it => it.minimumDownPayment)
    )

    const max = Math.max(
      ...products.productDurations.map(it => it.maximumDownPayment)
    )
    return {
      minimumDownPayment: min,
      maximumDownPayment: max,
    }
  } catch (e) {
    LoggerDebug('getHitch: No available products', e)
    return null
  }
}

export function getFinancialProducts(store, isPfa) {
  let { financialProducts } = store.financialProduct?.data || {
    financialProducts: [],
  }
  financialProducts = getFinancialProductsStore(isPfa, financialProducts, store)

  if (
    !hasFinancialProducts(store) &&
    (!financialProducts || !financialProducts.length)
  ) {
    return null
  }

  try {
    if (isObject(financialProducts)) {
      return financialProducts
    }

    const validProducts = financialProducts.filter(
      it =>
        it &&
        it.financialProductTypeId &&
        VALID_PRODUCT_IDS.includes(it.financialProductTypeId)
    )
    return !validProducts || validProducts.sort((a, b) => b.id - a.id)
  } catch (e) {
    LoggerDebug('getFinancialProducts: No available products', e)
    return null
  }
}

export function getFinancialProduct(store, productId, isPfa) {
  let { financialProducts } = store.financialProduct?.data || {
    financialProducts: [],
  }
  financialProducts = getFinancialProductsStore(isPfa, financialProducts, store)
  if (
    (!hasFinancialProducts(store) &&
      (!financialProducts || !financialProducts.length)) ||
    !productId
  ) {
    return null
  }
  try {
    const financialProduct = financialProducts.find(
      it => it && it.id && productId === it.id
    )
    sessionStorage.setItem('financialProduct', JSON.stringify(financialProduct))

    Logger(`financialProduct: ${JSON.stringify(financialProduct)}`)
    return financialProduct
  } catch (e) {
    LoggerDebug('getFinancialProduct: No available products', e)
    return null
  }
}

function getFinancialProductsStore(isPfa, financialProducts, store) {
  let financialProductsStore = financialProducts

  if (isMercadoLibre()) {
    /**
     *  If it is ACS and Audi vehicle, then we need to filter
     *  financial product names with  'Credit'. Otherwise, 'Credit Dwa'
     */
    const vehicleBrand = store.storefront.data?.vehicleData?.brand ?? process.env.REACT_APP_WEBSITE_BRAND
    if (vehicleBrand === 'audi' && isACS()) {
      try {
        const creditItems = financialProducts.find(
          element => element.name === 'Credit'
        )
        const audiNowItems = financialProducts.find(
          element => element.name === 'Audi Now'
        )
        financialProductsStore = [...creditItems, ...audiNowItems]
      } catch (e) {
        LoggerDebug(`getDurations default usados value`, e)
      }
    } else {
      try {
        financialProductsStore = financialProducts.find(
          element => element.name === 'Credit Dwa'
        )
      } catch (e) {
        LoggerDebug(`getDurations default usados value`, e)
      }
    }
  }
  return Array.isArray(financialProductsStore)
    ? financialProductsStore
    : [financialProductsStore]
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FINANCIAL_PRODUCT_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      }

    case FETCH_FINANCIAL_PRODUCT_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_FINANCIAL_PRODUCT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }
    case RESET_FINANCIAL_PRODUCT:
      return initialState

    default:
      return state
  }
}
