export const occupationTypes = [
  {
    value: 'employee',
    label: 'Recibos de nómina. Trabajo en una empresa y recibo un ingreso fijo',
  },
  {
    value: 'other',
    label:
      'Otros. Trabajo de manera independiente o cuento con un negocio propio',
  },
]
