import { axios } from '..'
import * as actions from '../../redux/features/financial-products.redux'
import { store } from '../../redux'
import { getRequestBrand } from '../../../config'
import { history } from '../../routing'

const {
  REACT_APP_PROXY_API_KEY,
  REACT_APP_GET_FINANCIAL_PRODUCTS,
} = process.env

export function getFinancialData(isPfa, brand, newUsed, modelYear) {
  let url = ''
  if (newUsed === 'new') {
    url = `${REACT_APP_GET_FINANCIAL_PRODUCTS}/new/${getRequestBrand(
      brand,
      newUsed
    )}/${isPfa}`
  } else {
    url = `${REACT_APP_GET_FINANCIAL_PRODUCTS}/used/${getRequestBrand(
      brand,
      newUsed
    )}/${modelYear}/${isPfa}`
  }
  return axios.get(url, {
    headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
    validateStatus: statusCode => statusCode === 200,
  })
}

export function loadFinancialDataToRedux(isPfa, brand, newUsed, modelYear) {
  return internalloadFinancialDataToRedux(
    store.dispatch,
    isPfa,
    brand,
    newUsed,
    modelYear
  )
}

function internalloadFinancialDataToRedux(
  dispatch,
  isPfa,
  brand,
  newUsed,
  modelYear
) {
  dispatch(actions.pending())
  return getFinancialData(isPfa, brand, newUsed, modelYear)
    .then(response => response)
    .then(data => dispatch(actions.done(data)))
    .catch(error => {
      history.push('/error')
      dispatch(actions.error(error))
      return Promise.reject(error)
    })
}
