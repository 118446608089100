/* eslint-disable global-require */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { i18n as i18nResources } from '../../resources/base/content'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: i18nResources,
    },
    lng: 'es',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
