import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentSection,
  FloatingBar,
  PageWrap,
  ProductInfoSummary,
  Paragraph,
} from '@vwfs-bronson/bronson-react'

import { formatCurrency } from '../../../services/common/format'
import { shouldShowLegend } from '../../../config'
import { isMercadoLibre, useTraceUpdate } from '../../../services/data/utils'

const scrollRef = React.createRef()

const VehicleDetails = props => {
  useTraceUpdate(props)
  const { vehicleData, usedNew, storefrontData } = props
  const { t } = useTranslation()
  const model = vehicleData.modelName || vehicleData.model
  const price = vehicleData.price || parseFloat(vehicleData.retailPrice) || 0
  const isValidData = () => {
    return (
      !!model && !!price && !!vehicleData.modelYear && !!vehicleData.version
    )
  }
  if (!isValidData(vehicleData)) {
    return <></>
  }
  const isAutocredit = storefrontData?.dataStorage?.realm.startsWith(
    'autocredit'
  )

  return (
    <React.Fragment>
      <div ref={scrollRef} />
      {(isMercadoLibre() || sessionStorage.getItem('storefrontId')) &&
        isAutocredit && (
          <ContentSection>
            <PageWrap>
              <>
                <ProductInfoSummary
                  className="c-product-info-summary__info-custom"
                  imgSrc={vehicleData.imageUrl}
                  imgAlt={model}
                  title={`${model} ${vehicleData.modelYear}`}
                  subtitle={vehicleData.version}
                  infoLabel={`${t('calculation:vehicleSelect:labels:price')} ${
                    shouldShowLegend(usedNew) ? '*' : ''
                  }`}
                  // infoLabel={`${t('calculation:vehicleSelect:labels:price')}*`}
                  infoValue={formatCurrency(price, 'symbol')}
                />
                {shouldShowLegend(usedNew) && (
                  <Paragraph className="c-definition-table__information u-font-size-fs-2 shopping-card-legend">
                    {t('calculation:vehicleSelect:priceDisclaimer')}
                  </Paragraph>
                )}
              </>
            </PageWrap>
          </ContentSection>
        )}
      {(isMercadoLibre() || sessionStorage.getItem('storefrontId')) &&
        isAutocredit && (
          <FloatingBar target={scrollRef}>
            <ProductInfoSummary
              imgSrc={vehicleData.imageUrl}
              imgAlt={vehicleData.modelName || vehicleData.model}
              title={`${vehicleData.modelName || vehicleData.model} ${
                vehicleData.modelYear
              }`}
              subtitle={vehicleData.version}
              infoLabel={t('calculation:vehicleSelect:labels:price')}
              infoValue={formatCurrency(vehicleData.retailPrice, 'symbol')}
            />
          </FloatingBar>
        )}
    </React.Fragment>
  )
}

export default VehicleDetails
