import React, { useEffect } from 'react'
import { Spinner } from '@vwfs-bronson/bronson-react'
import { loadStorefrontDataToRedux } from './storefront.service'
import { Logger } from '../../common/logger'

const loadData = (id, used) => loadStorefrontDataToRedux(id, used)
export default function WithStorefront({ id, used, children }) {
  const urlParams = new URLSearchParams(window.location.search)
  const storefrontId =
    id ?? urlParams.get('cid') ?? urlParams.get('esign') ?? ''
  Logger(`withStorfront storefrontId: ${storefrontId}`)
  Logger(`withStorfront used: ${used}`)

  const [isLoading, setLoading] = React.useState(true)
  useEffect(() => {
    loadData(storefrontId, used)
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false)
        return {}
      })
  }, [storefrontId, used])
  return (
    <>
      {isLoading && (
        <div className="u-p-xlarge">
          <Spinner small={false} section center />
        </div>
      )}
      {!isLoading && children}
    </>
  )
}
