import React from 'react'
import { Wizard } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

const CalculationPageWizard = () => {
  const { t } = useTranslation()
  return (
    <Wizard
      collapsedText={t('form:wizard:collapsedStep1')}
      noInteraction
      collapseBreakpoint="s"
    >
      <Wizard.Item key="step1" to="#" active>
        {t('form:wizard:step1')}
      </Wizard.Item>
      <Wizard.Item key="step2" to="#">
        {t('form:wizard:step2')}
      </Wizard.Item>
      <Wizard.Item key="step3" to="#">
        {t('form:wizard:step3')}
      </Wizard.Item>
      <Wizard.Item key="step4" to="#">
        {t('form:wizard:step4')}
      </Wizard.Item>
    </Wizard>
  )
}

export default CalculationPageWizard
