import { axios } from '..'
import * as actions from '../../redux/features/storefront.redux'
import { store } from '../../redux'
import { history } from '../../routing'
import { isACS, isMercadoLibre } from '../../data/utils'

const {
  REACT_APP_PROXY_API_KEY,
  REACT_APP_GET_STOREFRONT_DATA_URL,
  REACT_APP_STOREFRONT_BRAND,
  REACT_APP_STOREFRONT_COUNTRYCODE,
  REACT_APP_STOREFRONT_PRODUCT,
  REACT_APP_STOREFRONT_USEDNEW,
} = process.env

export async function getStorefrontData(cid, used = false) {
  const usedNew = getUsedNew(used)

  const url = `${REACT_APP_GET_STOREFRONT_DATA_URL}/${REACT_APP_STOREFRONT_PRODUCT}/${REACT_APP_STOREFRONT_COUNTRYCODE}/${REACT_APP_STOREFRONT_BRAND}/${usedNew}/data/${cid}`
  // eslint-disable-next-line prefer-const
  let res = {
    data: {}
  }
  try {
    res = await axios.get(url, {
      headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
      validateStatus: statusCode => statusCode === 200,
    })
  } catch (error) {
    console.error(error);
  }

  if (isACS()) {
    res.acs = true
  } else {
    res = { ...res, acs: false }
  }

  // To make new Storefront compatible with new old one:
  if (res.financialProduct) {
    if (res.financialProduct.bookingData) {
      res.bookingData = res.financialProduct.bookingData
    }
    res.financialData = res.financialProduct
  }

  // To make new Storefront compatible with new old one:
  if (res?.vehicleData?.usedCar) {
    res = {
      ...res,
      usedCar: res?.vehicleData?.usedCar,
      financialData: res?.financialProduct,
    }
  }

  return res
}

export function loadStorefrontDataToRedux(cid, used = false) {
  return internalLoadStorefrontDataToRedux(store.dispatch, cid, used)
}

function internalLoadStorefrontDataToRedux(dispatch, cid, used = false) {
  dispatch(actions.pending())
  return getStorefrontData(cid, used)
  .then(response => response)
  .then(data => {
      dispatch(actions.done(data))
      if (data?.usedCar) {
        if (!isMercadoLibre() && REACT_APP_STOREFRONT_BRAND !== 'audi') {
          history.push(`/usados/?cid=${cid}`)
          window.location.reload()
        }
      } else if (isMercadoLibre()) {
        history.push(`/?cid=${cid}`)
        window.location.reload()
      }
    })
    .then(() => {
      if (
        process.env.REACT_APP_WEBSITE_BRAND === 'porsche' ||
        process.env.REACT_APP_STOREFRONT_BRAND === 'porsche'
      ) {
        dispatch(actions.widget())
      }
    })
    .catch((e) => {
      console.error(e)
      history.push('/error', {
        state: {
          code: null,
          message: 'No available data',
          url: window.location.href,
        },
      })
    })
}

function getUsedNew(used) {
  if (used && REACT_APP_STOREFRONT_BRAND === 'audi') {
    return 'new'
  }
  if (used || isMercadoLibre()) {
    return 'used'
  }

  return REACT_APP_STOREFRONT_USEDNEW
}
