import i18n from 'i18next'
import Yup from '../../services/yup'

const ValidationSchema = Yup.object().shape({
  occupation: Yup.string().required(i18n.t('validation:required')),
  usedNew: Yup.string().required(i18n.t('validation:required')),
  brand: Yup.string().required(i18n.t('validation:required')),
  model: Yup.string().required(i18n.t('validation:required')),
  modelYear: Yup.string().required(i18n.t('validation:required')),
  version: Yup.string().required(i18n.t('validation:required')),
  price: Yup.string().test({
    name: 'price-positive',
    message: i18n.t('validation:minValue'),
    test: validatePricePositive,
  }),
  productId: Yup.number().required(i18n.t('validation:required')),
  downPaymentRate: Yup.number().required(i18n.t('validation:required')),
  duration: Yup.number().required(i18n.t('validation:required')),
})

function validatePricePositive() {
  const { price, usedNew } = this.parent

  return !isPriceRequired(usedNew) || price > 0
}

function isPriceRequired(usedNew) {
  return usedNew === 'used'
}

export default ValidationSchema
