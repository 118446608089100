import React from 'react'
import { Layout, ItemTeaser, Paragraph } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

const ContactPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Paragraph>{t('contact:text1')}</Paragraph>
      <Layout className="u-pb-small">
        <Layout.Item default="1/1" l="1/1">
          <ItemTeaser
            icon={t('contact:itemTeaser1:icon')}
            linkLabel={t('contact:itemTeaser1:linkText')}
            linkProps={{
              element: 'a',
              href: `mailto:${t(
                `contact:itemTeaser1:linkText`
              )}?subject=Duda preevaluación`,
            }}
          >
            {t('contact:itemTeaser1:text')}
          </ItemTeaser>
        </Layout.Item>
      </Layout>
    </>
  )
}
export default ContactPage
