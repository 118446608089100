/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  SectionHeading,
  Spinner,
  CustomRangeSlider,
} from '@vwfs-bronson/bronson-react'
import { ContentSection } from '../../../components/ContentSection'
import {
  getDurations,
  getHitch,
} from '../../../services/redux/features/financial-products.redux'

import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { isMercadoLibre, mobileCheck } from '../../../services/data/utils'

const HitchSlider = ({
  values,
  setFieldValue,
  durations,
  hitch,
  storefrontData,
}) => {
  useEffect(() => {
    if (
      durations &&
      durations.length &&
      values &&
      durations.indexOf(values.duration) === -1
    ) {
      setFieldValue('duration', durations[0] ? durations[0] : 0)
    }
  }, [values, durations, setFieldValue])

  const { t } = useTranslation()

  const calculateTermRange = () => {
    let range = { min: 0, max: 1 }
    if (durations && durations.length > 1) {
      range = { min: durations[0], max: durations[durations.length - 1] }
    }
    return range
  }

  const calculateTermSteps = () => {
    let steps = 6
    if (durations && durations.length > 1) {
      steps = durations[1] - durations[0]
    }
    return steps
  }

  const calculateHitchRange = () => {
    let range = { min: 0, max: 1 }
    if (hitch && hitch.minimumDownPayment && hitch.maximumDownPayment) {
      range = {
        min: hitch.minimumDownPayment * 100,
        max: hitch.maximumDownPayment * 100,
      }
    }
    return range
  }

  const calculateDensity = () => {
    let density = -1
    if (hitch && hitch.minimumDownPayment && hitch.maximumDownPayment) {
      density =
        100 /
        ((hitch.maximumDownPayment * 100 - hitch.minimumDownPayment * 100) / 5)
    }
    return density
  }
  const bookingPaymentExist =
    storefrontData?.bookingData && storefrontData?.bookingData.amount

  const showContent = () => {
    return (
      values.downPaymentRate &&
      values.downPaymentRate > 0 &&
      values.duration &&
      values.duration > 0
    )
  }
  return (
    <ContentSection>
      {!showContent() && <Spinner small={false} center />}
      <div className={showContent() ? '' : 'u-hide@s-up'}>
        <SectionHeading
          subtitle={
            bookingPaymentExist
              ? t('calculation:sliderSection:subHeading')
              : t('calculation:slider')
          }
        >
          {t('calculation:sliderSection:heading')}
        </SectionHeading>
        {hitch && hitch.minimumDownPayment && hitch.maximumDownPayment && (
          <CustomRangeSlider
            value={values.downPaymentRate}
            range={calculateHitchRange()}
            step={5}
            tooltips={
              mobileCheck()
                ? { to: v => `${Number(v).toLocaleString()} %` }
                : false
            }
            pips={{
              mode: 'steps',
              density: calculateDensity(),
              format: {
                to: val => {
                  let format = ''
                  if (mobileCheck()) {
                    if (
                      val === calculateHitchRange().min ||
                      val === calculateHitchRange().max
                    ) {
                      format = `${Number(val).toLocaleString()} %`
                    }
                  } else {
                    format = `${Number(val).toLocaleString()} %`
                  }

                  return format
                },
              },
            }}
            onSet={value => {
              const numericValue = parseInt(value, 10)
              setFieldValue('downPaymentRate', numericValue)
            }}
          />
        )}

        <br />
        <br />
        {durations?.length !== 1 && (
          <SectionHeading subtitle={t('calculation:slider2')} />
        )}
        {durations?.length > 1 && (
          <CustomRangeSlider
            key={`${values.occupation.toString()}-${values.productId.toString()}`}
            value={values.duration}
            range={calculateTermRange()}
            step={calculateTermSteps()}
            tooltips={
              mobileCheck()
                ? { to: v => `${Number(v).toLocaleString()} meses` }
                : false
            }
            pips={{
              mode: 'steps',
              density: -1,
              format: {
                to: val => {
                  let format = ''
                  if (mobileCheck()) {
                    if (
                      val === calculateTermRange().min ||
                      val === calculateTermRange().max
                    ) {
                      format = `${Number(val).toLocaleString()}`
                    }
                  } else {
                    format = `${Number(val).toLocaleString()}`
                  }
                  return format
                },
              },
            }}
            onSet={value => {
              const numericValue = parseInt(value, 10)
              setFieldValue('duration', numericValue)
            }}
          />
        )}
      </div>
    </ContentSection>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { values } = ownProps
  const { productId, downPaymentRate } = values
  let durations = []

  if ((productId && downPaymentRate) || isMercadoLibre()) {
    durations = getDurations(
      state,
      parseInt(productId, 10),
      downPaymentRate / 100,
      values.occupation === 'employee'
    )
  }

  return {
    durations,
    hitch: productId ? getHitch(state, parseInt(productId, 10)) : [],
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps)(HitchSlider)
