/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { SectionHeading, Spinner } from '@vwfs-bronson/bronson-react'
import { ContentSection } from '../../../components/ContentSection'
import { getPricingData } from '../../../services/redux/features/pricing.redux'
import {
  getMercadoLibreModel,
  getVehicleData,
} from '../../../services/redux/features/catalog.redux'

import {
  loadVehicleModelsToRedux,
  mapBrandUsedNew,
} from '../../../services/api/catalog/catalog.service'
import { loadSimulationAmortizationToRedux } from '../../../services/api/simulation-amortization/simulation-amortization.service'

import OccupationRadio from './OccupationRadio'
import VehicleSelection from './VehicleSelection'
import VehicleDetails from './VehicleDetails'
import PlanSelection from './PlanSelection'
import HitchSlider from './HitchSlider'
import PaymentSummary from './PaymentSummary'
import AmortizationTable from './AmortizationTable'
import { getFinancialProducts } from '../../../services/redux/features/financial-products.redux'
import { getStorefrontData } from '../../../services/redux/features/storefront.redux'
import { store } from '../../../services/redux'
import { resetData } from '../field-definitions'
import { Analytics } from '../../../services/analytics'
import { getBrandName } from '../../../config'
import { history } from '../../../services/routing'
import {
  hashCode,
  isMercadoLibre,
  isACS,
  useTraceUpdate,
} from '../../../services/data/utils'
import {
  Logger,
  LoggerDebug,
  LoggerError,
} from '../../../services/common/logger'

const Sections = props => {
  useTraceUpdate(props)

  const { t } = useTranslation()
  const {
    dataModels,
    values,
    setFieldValue,
    financialProducts,
    mercadoLibreVehicleModel,
    amortizationRendered,
    setErrors,
    pricingData,
    vehicleData,
    storefrontData,
    priceUpdateCallback,
    isLoadingPrice,
    setLoadingPrice,
    formProps,
  } = props

  LoggerDebug('Sections getMercadoLibreModel', mercadoLibreVehicleModel)

  const brand = getBrandName()
  const [isLoadingAmortizationTable, setLoadingAmortizationTable] = useState(
    false
  )

  const [carSelectionVisible, setCarSelectionVisible] = useState(false)
  const [storefrontVehicleData, setStorefrontVehicleData] = useState({})
  const [validStorefrontPricing, setValidStorefrontPricing] = useState(false)
  const [settingOccupation, setSettingOccupation] = useState(false)

  const mapBrand = brand_ => {
    switch (brand_.toLowerCase()) {
      case 'vw':
        return 'volkswagen'
      default:
        return brand_
    }
  }

  const matchPriceWithStorefront = model => {
    let { vehicleModels, brandVehicleModels } = store.getState().catalog.data
    if ((!vehicleModels || storefrontData?.usedCar) && brandVehicleModels) {
      vehicleModels = brandVehicleModels.find(it => {
        return (
          it.brandName.toLowerCase() === model.brand.toLowerCase() ||
          mapBrand(it.brandName.toLowerCase()) ===
            mapBrand(model.brand.toLowerCase())
        )
      }).vehicleModels
    }
    try {
      if (Array.isArray(vehicleModels)) {
        // Handle array format for standalone flow
        const vehicleModel = vehicleModels.find(
          models => models.name === model.modelName
        )

        if (vehicleModel && vehicleModel.trimLines) {
          const vehicleYear = vehicleModel.trimLines.find(
            trimLine => trimLine.modelYear === model.modelYear
          )
          if (vehicleYear && vehicleYear.trimLineDetails) {
            const modelData = vehicleYear.trimLineDetails.find(
              version => version.name === model.version
            )
            return (modelData && modelData.retailPrice) || ''
          }
        }
      } else if (vehicleModels && vehicleModels.vehicleModel) {
        // Handle single object format for storefronts flow
        const vehicleModel = vehicleModels.vehicleModel

        if (
          vehicleModel.name === model.modelName &&
          vehicleModel.trimLine &&
          vehicleModel.trimLine.modelYear === model.modelYear &&
          vehicleModel.trimLine.trimLineDetail &&
          vehicleModel.trimLine.trimLineDetail.name === model.version
        ) {
          return vehicleModel.trimLine.trimLineDetail.retailPrice || ''
        }
      }
      return ''
    } catch (error) {
      throw new Error(`No VehicleModels available: ${error.message}`)
    }
  }

  const allowedBrand = {
    VW: 'vw',
    VWN: 'vw-lcv',
    PORSCHE: 'porsche',
    DUCATI: 'ducati',
    AUDI: 'audi',
    SEAT: 'seat',
  }

  const getVehicleDataFromStorefront = () => {
    const vehicleData =
      storefrontData?.vehicleData ||
      storefrontData?.dataStorage?.data?.vehicleData
    const modelName =
      storefrontData?.vehicleData?.modelName ||
      storefrontData?.dataStorage?.data?.vehicleData?.modelName
    const model =
      storefrontData?.vehicleData?.model ||
      storefrontData?.dataStorage?.data?.vehicleData?.model
    const modelYear =
      storefrontData?.vehicleData?.modelYear ||
      storefrontData?.dataStorage?.data?.vehicleData?.modelYear
    const version =
      storefrontData?.vehicleData?.version ||
      storefrontData?.dataStorage?.data?.vehicleData?.version
    const purchasePriceAmount =
      storefrontData?.vehicleData?.purchasePriceAmount ||
      storefrontData?.dataStorage?.data?.vehicleData?.purchasePriceAmount
    if (
      !isEmpty(vehicleData) &&
      (modelName || model) &&
      modelYear &&
      version &&
      purchasePriceAmount
    ) {
      const list = Object.values(allowedBrand)
      const isPfa = values.occupation === 'employee'

      const used =
        storefrontData?.usedCar ??
        storefrontData?.vehicleData?.usedCar ??
        storefrontData?.dataStorage?.data?.vehicleData?.usedCar
          ? 'used'
          : 'new'
      let vehicleManufacturer = brand
      if (!brand || !list.includes(brand)) {
        vehicleManufacturer =
          storefrontData && storefrontData?.vehicleData
            ? storefrontData?.vehicleData?.brand
            : 'vw'
      }
      loadVehicleModelsToRedux(isPfa, vehicleManufacturer, used)
        .then(data => {
          LoggerDebug(`data: ${JSON.stringify(data)}`)
          const vehicleBrand =
            storefrontData?.vehicleData?.brand ||
            storefrontData?.dataStorage?.brand ||
            ''
          if (used === 'new') {
            const retailPrice = matchPriceWithStorefront(
              storefrontData.vehicleData ||
                storefrontData?.dataStorage?.data?.vehicleData
            )
            const vechiclePrice =
              store.getState().catalog.data?.vehicleModel?.trimLine
                ?.trimLineDetail?.retailPrice ||
              storefrontData?.dataStorage?.data?.financialProduct?.calculation
                ?.retailPrice
            const modelPrice =
              storefrontData?.vehicleData?.retailPrice ||
              storefrontData?.dataStorage?.data?.vehicleData?.retailPrice ||
              retailPrice ||
              values.price ||
              vechiclePrice
            if (modelPrice) {
              setFieldValue('price', modelPrice)
              setFieldValue('usedNew', used)
              setFieldValue('brand', vehicleBrand)
              setStorefrontVehicleData({
                ...storefrontData?.vehicleData,
                ...storefrontData?.dataStorage?.data?.vehicleData,
                modelPrice,
              })
              setValidStorefrontPricing(true)
            } else {
              setCarSelectionVisible(true)
            }
          } else {
            const modelPrice = storefrontData?.vehicleData?.purchasePriceAmount

            if (modelPrice) {
              setFieldValue('price', modelPrice)
              setFieldValue('usedNew', used)
              setFieldValue('brand', vehicleBrand)
              setStorefrontVehicleData({
                ...storefrontData.vehicleData,
                ...storefrontData?.dataStorage?.data?.vehicleData,
                modelPrice,
              })
              setValidStorefrontPricing(true)
              if (isMercadoLibre() && !isACS()) {
                loadPrices()
              } else if (
                isMercadoLibre() &&
                isACS() &&
                storefrontData.vehicleData.brand === 'audi'
              ) {
                if (validateVehicle(data)) {
                  loadPrices()
                } else {
                  history.push('/error', {
                    state: {
                      code: null,
                      message: 'Vehicle not identified',
                      url: window.location.href,
                    },
                  })
                }
              }
            } else {
              setCarSelectionVisible(true)
            }
          }
        })
        .catch(error => {
          LoggerError('loadVehicleModelsToRedux', error)
          history.push('/error', {
            state: {
              code: null,
              message: 'Invalid brand',
              url: window.location.href,
            },
          })
        })
    } else {
      setCarSelectionVisible(true)
    }
  }

  useEffect(() => {
    getVehicleDataFromStorefront()
    resetData(values.brand, setFieldValue, values.occupation)
  }, [])

  const validateVehicle = data => {
    if (
      data &&
      data.brandVehicleModels &&
      storefrontData &&
      storefrontData.vehicleData.brand
    ) {
      const brandToSearch =
        storefrontData.vehicleData.brand.toLowerCase() === 'vw'
          ? 'volkswagen'
          : storefrontData.vehicleData.brand
      const brandVehicles = data.brandVehicleModels.find(item => {
        return item.brandName.toLowerCase() === brandToSearch.toLowerCase()
      })
      const vehicleModel = brandVehicles.vehicleModels.find(item => {
        return (
          item.name.toLowerCase() ===
          storefrontData.vehicleData.modelName.toLowerCase()
        )
      })

      const foundVehicle = vehicleModel.trimLines.find(item => {
        return item.modelYear === storefrontData.vehicleData.modelYear
      })
      return foundVehicle
    }
    return true
  }

  const showFinancePlan = () => {
    return !!(
      values &&
      values.occupation &&
      values.usedNew &&
      values.brand &&
      values.model &&
      values.modelYear &&
      values.version &&
      (values.price ||
        values.usedNew === 'new' ||
        values.price ||
        values.usedNew === 'used')
    )
  }
  const loadPrices = useCallback(() => {
    let prop = {
      ...values,
    }

    if (isMercadoLibre()) {
      prop = {
        ...prop,
        price:
          storefrontData?.vehicleData?.purchasePriceAmount ||
          storefrontData?.dataStorage?.data?.vehicleData?.purchasePriceAmount,
      }
    }
    const product =
      financialProducts && financialProducts.length
        ? financialProducts[0].id.toString()
        : ''
    prop = {
      ...prop,
      productId: values.productId || product,
    }
    if (!values.productId && brand === 'audi') {
      return
    }
    loadSimulationAmortizationToRedux(prop, storefrontData)
      .then(result => {
        try {
          if (!result) {
            throw new Error()
          }
          setLoadingPrice(false)
          setLoadingAmortizationTable(true)
          setSettingOccupation(false)
        } catch (e) {
          LoggerError('loadSimulationAmortizationToRedux error', e)
          // throw e
        }
      })
      .catch(e => {
        LoggerError('loadSimulationAmortizationToRedux', e)
        // throw e
      })
      .finally(() => {
        setLoadingPrice(false)
        setLoadingAmortizationTable(false)
        amortizationRendered(true)
      })
  })

  useEffect(() => {
    if (values.productId === '2') {
      Analytics.mergeDefaults({
        product: [
          {
            name: 'AutoCredit',
          },
        ],
      })
    } else if (values.productId === '1') {
      Analytics.mergeDefaults({
        product: [
          {
            name: 'ClassicCredit',
          },
        ],
      })
    }
  })
  if (!window.debouncer) {
    window.debouncer = {}
  }

  const debounceMX = (funct, time) => {
    const hash = hashCode(funct.toString())
    if (window.debouncer[hash]) {
      clearTimeout(window.debouncer[hash])
    }
    const timeout = setTimeout(() => {
      funct()
    }, time)
    window.debouncer[hash] = timeout
  }
  useEffect(() => {
    if (values.downPaymentRate === 0 || values.duration === 0) {
      return
    }
    if (showFinancePlan() === true) {
      setLoadingPrice(true)
      setLoadingAmortizationTable(true)
      setSettingOccupation(false)

      debounceMX(loadPrices, 2000)
    }
  }, [values.downPaymentRate, values.duration, values.productId])

  useEffect(() => {
    if (financialProducts && financialProducts.length && !values.productId) {
      const productId = financialProducts[0].id.toString()
      setFieldValue('productId', productId)
      setTimeout(() => {
        setDefaultValues()
      }, 0)
    }
  }, [financialProducts])

  useEffect(() => {
    if (
      process.env.REACT_APP_WEBSITE_BRAND === 'dwa' ||
      (process.env.REACT_APP_WEBSITE_BRAND === 'vwfs' &&
        values.usedNew === 'used') ||
      (process.env.REACT_APP_WEBSITE_BRAND === 'audi' &&
        values.usedNew === 'used')
    ) {
      loadPrices()
    }
  }, [values.price, values.usedNew])

  const setDefaultValues = () => {
    const productId = storefrontData?.financialData?.salesCondition?.productId
    if (productId) {
      setFieldValue('productId', productId.toString())
    }
    const downPaymentRate =
      storefrontData?.financialData?.calculation?.downPaymentPercentage
    if (downPaymentRate) {
      setFieldValue('downPaymentRate', downPaymentRate * 100)
    } else {
      setFieldValue('downPaymentRate', 25)
    }
    const duration = storefrontData?.financialData?.calculation?.duration || 24
    if (duration) {
      setFieldValue('duration', duration)
    }
  }

  const amortizationTable = !!(
    values &&
    (((values.usedNew === 'new' || values.usedNew === 'used') &&
      values.version &&
      validStorefrontPricing) ||
      values.price)
  )

  const checkVehicleData = () => {
    if (
      storefrontVehicleData &&
      storefrontVehicleData.version &&
      (storefrontVehicleData.retailPrice || values.price)
    ) {
      return storefrontVehicleData
    }
    if (vehicleData && vehicleData.models) {
      return {
        ...values,
      }
    }
    return false
  }

  return (
    <>
      {isLoadingPrice &&
        ((brand !== 'vwfs' &&
          (!amortizationTable ||
            financialProducts.length === 0 ||
            !showFinancePlan() ||
            !checkVehicleData())) ||
          (brand === 'vwfs' && settingOccupation)) && <Spinner fullPage />}
      <>
        <ContentSection altBackground>
          <SectionHeading number={2} center>
            {brand === 'vwcv'
              ? t('calculation:heading_vwcv')
              : t('calculation:heading')}
          </SectionHeading>
          <SectionHeading number={2} center>
            {t('calculation:heading2')}
          </SectionHeading>
        </ContentSection>
        {checkVehicleData() && (
          <VehicleDetails
            vehicleData={{
              ...checkVehicleData(),
              retailPrice:
                checkVehicleData().retailPrice ||
                vehicleData.price ||
                values.price,
            }}
            usedNew={values.usedNew}
            storefrontData={storefrontData}
          />
        )}
        <OccupationRadio
          dataModels={dataModels}
          values={values}
          setFieldValue={setFieldValue}
          resetData={resetData}
          store={store}
          onChangeValue={() => {
            Logger(`values ${JSON.stringify(values)}`)
            setErrors({})
            setSettingOccupation(true)
          }}
          settingOccupation={setSettingOccupation}
        />
        {carSelectionVisible && (
          <VehicleSelection
            dataModels={dataModels}
            values={values}
            formProps={formProps}
            setFieldValue={setFieldValue}
            vehicleData={vehicleData}
            resetData={resetData}
            priceUpdateCallback={priceUpdateCallback}
          />
        )}
        {amortizationTable && (
          <>
            <PlanSelection
              values={values}
              setFieldValue={setFieldValue}
              financialProducts={financialProducts}
              setSettingOccupation={setSettingOccupation}
            />
            <HitchSlider values={values} setFieldValue={setFieldValue} />
            <PaymentSummary
              values={values}
              isLoadingPrice={isLoadingPrice}
              pricingData={pricingData}
              vehicleData={vehicleData}
            />
            <AmortizationTable
              dataModels={dataModels}
              values={values}
              isLoadingAmortizationTable={isLoadingAmortizationTable}
              pricingData={pricingData}
              amortizationRendered={amortizationRendered}
            />
          </>
        )}
      </>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { values } = ownProps
  const { brand, model, modelYear, version, usedNew, price } = values
  const catalogBrand = mapBrandUsedNew(brand, usedNew)

  return {
    mercadoLibreVehicleModel: getMercadoLibreModel(state),
    financialProducts: getFinancialProducts(
      state,
      values.occupation === 'employee'
    ),
    pricingData: getPricingData(state),
    vehicleData: getVehicleData(
      state,
      usedNew,
      catalogBrand,
      model,
      modelYear,
      version,
      price
    ),
    storefrontData: getStorefrontData(state),
  }
}

export default connect(mapStateToProps)(Sections)
