import {
  format as fnsFormat,
  differenceInYears,
  isFuture,
  isPast,
  addDays,
} from 'date-fns'

import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'dateFormat', function format(
  formats,
  dateSeperator,
  message
) {
  return this.test('testFormat', message, function testFormat(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        value === fnsFormat(new Date(date[2], date[1] - 1, date[0]), formats)
      )
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'fullAge', function fullAge(
  years,
  dateSeperator,
  message
) {
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        differenceInYears(
          new Date(),
          new Date(date[2], date[1] - 1, date[0])
        ) >= years
      )
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'maxAge', function maxAge(
  years,
  dateSeperator,
  message
) {
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        differenceInYears(
          new Date(),
          new Date(date[2], date[1] - 1, date[0])
        ) <= years
      )
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'minDate', function maxAge(
  maxDate,
  dateSeperator,
  message
) {
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const dateArray = value.split(dateSeperator)
      const maxDateArray = maxDate.split(dateSeperator)
      return dateArray[2] >= maxDateArray[2]
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'notInFuture', function notInFuture(
  dateSeperator,
  message
) {
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return !isFuture(new Date(date[2], date[1] - 1, date[0]))
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'yearNotInFuture', function notInFuture(message) {
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const currentYear = new Date().getFullYear()
      return currentYear >= value
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'notInPast', function notInPast(
  dateSeperator,
  message
) {
  return this.test('testDateNotInPast', message, function testDateNotInPast(
    value
  ) {
    if (value) {
      const dateArr = value.split(dateSeperator)
      const date = addDays(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]), 1)
      return !isPast(date)
    }
    return true
  })
})

Yup.addMethod(Yup.string, 'noLeadingWhitespace', function noLeadingWhitespace(
  message
) {
  return this.test('testFormat', message, function testFormat(value) {
    if (!value) {
      return true
    }
    return value.match(/^[^\s].*$/)
  })
})

Yup.addMethod(Yup.string, 'minValue', function minValue(
  message,
  minValueParam
) {
  return this.test('testMin', message, function testMin(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) >= minValueParam
  })
})
Yup.addMethod(Yup.string, 'maxValue', function maxValue(
  message,
  maxValueParam
) {
  return this.test('testMax', message, function testMax(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) <= maxValueParam
  })
})

export default Yup
