import { useEffect, useRef } from 'react'
import { Logger } from '../common/logger'

export const calculatePayments = (downPaymentRate, price, pricingData) => {
  const result = {}
  result.totalDownPayment =
    price && downPaymentRate ? price * (downPaymentRate / 100) : null
  result.loanAmount = result.totalDownPayment
    ? price - result.totalDownPayment
    : null
  result.initialPayment =
    result.totalDownPayment && Object.entries(pricingData).length
      ? result.totalDownPayment + pricingData?.commissionChargeAmount
      : null
  result.bookingAmount =
    pricingData?.bookingAmount ||
    pricingData?.dataStorage?.data?.financialProduct?.bookingData?.amount
  result.remainingHitch = pricingData?.remainingHitch
  return result
}

export const isMercadoLibre = () => {
  if (
    window.location.pathname.indexOf('usados') !== -1 ||
    sessionStorage.getItem('isMercadoLibre') === 'true'
  ) {
    sessionStorage.setItem('isMercadoLibre', true)
    return true
  }
  return false
}

export const isACS = () => {
  if (
    window.location.pathname.indexOf('acs') !== -1 ||
    sessionStorage.getItem('isACS') === 'true'
  ) {
    sessionStorage.setItem('isACS', true)
    return true
  }
  return false
}

export const isESignCompletion = () => {
  if (
    window.location.pathname.indexOf('esign') !== -1 ||
    sessionStorage.getItem('isESignCompletion') === 'true'
  ) {
    sessionStorage.setItem('isESignCompletion', true)
    return true
  }
  return false
}

export const removeNulls = obj =>
  // eslint-disable-next-line no-unused-vars
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))

export const replaceNullsWithEmptyString = obj => {
  const handleValue = value => {
    if (value === null) {
      return ''
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      return replaceNullsWithEmptyString(value)
    }
    return value
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, handleValue(value)])
  )
}

export function useTraceUpdate(props) {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        // eslint-disable-next-line no-param-reassign
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      Logger('Changed props:', changedProps)
    }
    prev.current = props
  })
}

export const isObject = (input: any): boolean => {
  return Object.prototype.toString.call(input) === '[object Object]'
}

export const checkNotNulls = input => {
  return Object.values(input).indexOf(null) === -1
}

export const mobileCheck = () => {
  return window.matchMedia('(max-width: 768px)').matches
}

/* eslint-disable */
export function hashCode(str) {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    )
}
