import { axios } from '../..'
import { history } from '../../../routing'

const { REACT_APP_PROXY_API_KEY, REACT_APP_SIMULATION } = process.env

export function finishSimulation(storefrontId) {
  const url = `${REACT_APP_SIMULATION}/${storefrontId}`
  return axios
    .post(
      url,
      {},
      {
        headers: {
          'X-API-KEY': REACT_APP_PROXY_API_KEY,
        },
        validateStatus: statusCode => statusCode === 200,
      }
    )
    .catch(e => {
      history.push('/error', {
        state: {
          code: null,
          message: `finishSimulation ${e}`,
          url: window.location.href,
        },
      })
      throw e
    })
}
