export const countryList = [
  { value: '', label: 'Seleccionar' },
  { value: 'MX', label: 'México' },
  { value: 'AF', label: 'Afganistán' },
  { value: 'AX', label: 'Aland, Islas' },
  { value: 'AL', label: 'Albania' },
  { value: 'DE', label: 'Alemania' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguila' },
  { value: 'AQ', label: 'Antártida' },
  { value: 'AG', label: 'Antigua y Barbuda' },
  { value: 'SA', label: 'Arabia Saudita' },
  { value: 'DZ', label: 'Argelia' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaiyán' },
  { value: 'BS', label: 'Bahamas (las)' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BH', label: 'Bahrein' },
  { value: 'BE', label: 'Bélgica' },
  { value: 'BZ', label: 'Belice' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermudas' },
  { value: 'BY', label: 'Belarús' },
  { value: 'BO', label: 'Bolivia (Estado Plurinacional de)' },
  { value: 'BQ', label: 'Bonaire, San Eustaquio y Saba' },
  { value: 'BA', label: 'Bosnia y Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BR', label: 'Brasil' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BT', label: 'Bhután' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KH', label: 'Camboya' },
  { value: 'CM', label: 'Camerún' },
  { value: 'CA', label: 'Canadá' },
  { value: 'QA', label: 'Qatar' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CY', label: 'Chipre' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoras (las)' },
  { value: 'KP', label: 'Corea (la República Popular Democrática de)' },
  { value: 'KR', label: 'Corea (la República de)' },
  { value: 'CI', label: 'Côte d Ivoire' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croacia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'DK', label: 'Dinamarca' },
  { value: 'DM', label: 'Dominica' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egipto' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'AE', label: 'Emiratos Árabes Unidos (los)' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'SK', label: 'Eslovaquia' },
  { value: 'SI', label: 'Eslovenia' },
  { value: 'ES', label: 'España' },
  { value: 'US', label: 'Estados Unidos de América (los)' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Etiopía' },
  { value: 'PH', label: 'Filipinas (las)' },
  { value: 'FI', label: 'Finlandia' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FR', label: 'Francia' },
  { value: 'GA', label: 'Gabón' },
  { value: 'GM', label: 'Gambia (la)' },
  { value: 'GE', label: 'Georgia' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GD', label: 'Granada' },
  { value: 'GR', label: 'Grecia' },
  { value: 'GL', label: 'Groenlandia' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GF', label: 'Guayana Francesa' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea Bissau' },
  { value: 'GQ', label: 'Guinea Ecuatorial' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haití' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungría' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IR', label: 'Irán (República Islámica de)' },
  { value: 'IE', label: 'Irlanda' },
  { value: 'BV', label: 'Bouvet, Isla' },
  { value: 'IM', label: 'Isla de Man' },
  { value: 'CX', label: 'Navidad, Isla de' },
  { value: 'IS', label: 'Islandia' },
  { value: 'KY', label: 'Caimán, (las) Islas' },
  { value: 'CC', label: 'Cocos / Keeling, (las) Islas' },
  { value: 'CK', label: 'Cook, (las) Islas' },
  { value: 'FO', label: 'Feroe, (las) Islas' },
  { value: 'GS', label: 'Georgia del Sur (la) y las Islas Sandwich del Sur' },
  { value: 'HM', label: 'Heard (Isla) e Islas McDonald' },
  { value: 'FK', label: 'Malvinas [Falkland], (las) Islas' },
  { value: 'MP', label: 'Marianas del Norte, (las) Islas' },
  { value: 'MH', label: 'Marshall, (las) Islas' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'SB', label: 'Salomón, Islas' },
  { value: 'TC', label: 'Turcas y Caicos, (las) Islas' },
  {
    value: 'UM',
    label: 'Islas Ultramarinas Menores de los Estados Unidos (las)',
  },
  { value: 'VG', label: 'Vírgenes británicas, Islas' },
  { value: 'VI', label: 'Vírgenes de los Estados Unidos, Islas' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italia' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japón' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordania' },
  { value: 'KZ', label: 'Kazajstán' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KG', label: 'Kirguistán' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'LA', label: 'Lao, (la) República Democrática Popular' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LV', label: 'Letonia' },
  { value: 'LB', label: 'Líbano' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lituania' },
  { value: 'LU', label: 'Luxemburgo' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia del Norte' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MY', label: 'Malasia' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MV', label: 'Maldivas' },
  { value: 'ML', label: 'Malí' },
  { value: 'MT', label: 'Malta' },
  { value: 'MA', label: 'Marruecos' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MU', label: 'Mauricio' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'FM', label: 'Micronesia (Estados Federados de)' },
  { value: 'MD', label: 'Moldova (la República de)' },
  { value: 'MC', label: 'Mónaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Níger (el)' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk, Isla' },
  { value: 'NO', label: 'Noruega' },
  { value: 'NC', label: 'Nueva Caledonia' },
  { value: 'NZ', label: 'Nueva Zelandia' },
  { value: 'OM', label: 'Omán' },
  { value: 'NL', label: 'Países Bajos (los)' },
  { value: 'PK', label: 'Pakistán' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestina, Estado de' },
  { value: 'PA', label: 'Panamá' },
  { value: 'PG', label: 'Papua Nueva Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Perú' },
  { value: 'PF', label: 'Polinesia Francesa' },
  { value: 'PL', label: 'Polonia' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  {
    value: 'GB',
    label: 'Reino Unido de Gran Bretaña e Irlanda del Norte (el)',
  },
  { value: 'EH', label: 'Sahara Occidental' },
  { value: 'CF', label: 'República Centroafricana (la)' },
  { value: 'CZ', label: 'Chequia' },
  { value: 'CG', label: 'Congo (el)' },
  { value: 'CD', label: 'Congo (la República Democrática del)' },
  { value: 'DO', label: 'Dominicana, (la) República' },
  { value: 'RE', label: 'Reunión' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'RO', label: 'Rumania' },
  { value: 'RU', label: 'Rusia, (la) Federación de' },
  { value: 'WS', label: 'Samoa' },
  { value: 'AS', label: 'Samoa Americana' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'KN', label: 'Saint Kitts y Nevis' },
  { value: 'SM', label: 'San Marino' },
  { value: 'MF', label: 'Saint Martin (parte francesa)' },
  { value: 'PM', label: 'San Pedro y Miquelón' },
  { value: 'VC', label: 'San Vicente y las Granadinas' },
  { value: 'SH', label: 'Santa Helena, Ascensión y Tristán de Acuña' },
  { value: 'LC', label: 'Santa Lucía' },
  { value: 'ST', label: 'Santo Tomé y Príncipe' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra leona' },
  { value: 'SG', label: 'Singapur' },
  { value: 'SX', label: 'Sint Maarten (parte neerlandesa)' },
  { value: 'SY', label: 'República Árabe Siria' },
  { value: 'SO', label: 'Somalia' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SZ', label: 'Swazilandia' },
  { value: 'ZA', label: 'Sudáfrica' },
  { value: 'SD', label: 'Sudán (el)' },
  { value: 'SS', label: 'Sudán del Sur' },
  { value: 'SE', label: 'Suecia' },
  { value: 'CH', label: 'Suiza' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard y Jan Mayen' },
  { value: 'TH', label: 'Tailandia' },
  { value: 'TW', label: 'Taiwán (Provincia de China)' },
  { value: 'TZ', label: 'Tanzania, República Unida de' },
  { value: 'TJ', label: 'Tayikistán' },
  { value: 'IO', label: 'Territorio Británico del Océano Índico (el)' },
  { value: 'TF', label: 'Tierras Australes Francesas (las)' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad y Tobago' },
  { value: 'TN', label: 'Túnez' },
  { value: 'TM', label: 'Turkmenistán' },
  { value: 'TR', label: 'Turquía' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UA', label: 'Ucrania' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistán' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Santa Sede (la)' },
  { value: 'VE', label: 'Venezuela (República Bolivariana de)' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'WF', label: 'Wallis y Futuna' },
  { value: 'YE', label: 'Yemen' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
]

export const stateList = [
  { value: '', label: 'Seleccionar' },
  { value: '001', label: 'Aguascalientes' },
  { value: '002', label: 'Baja California' },
  { value: '003', label: 'Baja California Sur' },
  { value: '004', label: 'Campeche' },
  { value: '005', label: 'Chihuahua' },
  { value: '006', label: 'Chiapas' },
  { value: '007', label: 'Coahuila' },
  { value: '008', label: 'Colima' },
  { value: '009', label: 'Durango' },
  { value: '010', label: 'Guanajuato' },
  { value: '011', label: 'Guerrero' },
  { value: '012', label: 'Hidalgo' },
  { value: '013', label: 'Jalisco' },
  { value: '014', label: 'México' },
  { value: '015', label: 'Michoacán' },
  { value: '016', label: 'Morelos' },
  { value: '017', label: 'Nayarit' },
  { value: '018', label: 'Nuevo León' },
  { value: '019', label: 'Oaxaca' },
  { value: '020', label: 'Puebla' },
  { value: '021', label: 'Querétaro' },
  { value: '022', label: 'Quintana Roo' },
  { value: '023', label: 'San Luis Potosí' },
  { value: '024', label: 'Sinaloa' },
  { value: '025', label: 'Sonora' },
  { value: '026', label: 'Tabasco' },
  { value: '027', label: 'Tamaulipas' },
  { value: '028', label: 'Tlaxcala' },
  { value: '029', label: 'Veracruz' },
  { value: '030', label: 'Yucatán' },
  { value: '031', label: 'Zacatecas' },
]
