import { removeNullFromArray } from '../../common/format'

export const FETCH_DEALERS_PENDING = 'dealers//FETCH_DEALERS_PENDING'

export const FETCH_DEALERS_DONE = 'dealers//FETCH_DEALERS_DONE'

export const FETCH_DEALERS_ERROR = 'dealers//FETCH_DEALERS_ERROR'

export const RESET_DEALERS = 'dealers//RESET_DEALERS'

export const SET_DEALER_ID = 'dealers//SET_DEALER_ID'

export const SET_DEALER_DATA = 'dealers//SET_DEALER_DATA'

export function pending() {
  return {
    type: FETCH_DEALERS_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_DEALERS_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_DEALERS_ERROR,
    payload: {
      err,
    },
  }
}

export function reset() {
  return {
    type: RESET_DEALERS,
  }
}

export function getDealers(store) {
  return removeNullFromArray(store.dealers.data ?? [])
}

export function setDealerId(data) {
  return {
    type: SET_DEALER_ID,
    payload: {
      data,
    },
  }
}

export function setDealerData(data) {
  return {
    type: SET_DEALER_DATA,
    payload: {
      data,
    },
  }
}

export function getDealerId(store) {
  return store.dealers.id
}

export function getDealerData(store) {
  return store.dealers.dealer.data
}

export function getDealerByPostalCode(store, postalCode) {
  if (!Array.isArray(getDealers(store)) || !postalCode) return null
  return getDealers(store).filter(it => it.address.postalCode === postalCode)
}

export function getDealerByCity(store, city) {
  if (!Array.isArray(getDealers(store)) || !city) return null
  return getDealers(store).filter(
    it => it.address.city.toLowerCase() === city.toLowerCase()
  )
}

export function getDealerByDealerCode(store, dealerCode) {
  if (!Array.isArray(getDealers(store)) || !dealerCode) return null
  return getDealers(store).find(it => it.dealerCode === dealerCode)
}

const initialState = {
  pending: false,
  data: [],
  error: null,
  dealer: {
    id: null,
    data: null,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEALERS_PENDING:
      return {
        ...state,
        data: [],
        pending: true,
        error: null,
      }
    case FETCH_DEALERS_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_DEALERS_ERROR:
      return {
        ...state,
        data: [],
        pending: false,
        error: true,
      }
    case RESET_DEALERS:
      return initialState
    case SET_DEALER_ID:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          id: action.payload.data,
        },
      }
    case SET_DEALER_DATA:
      return {
        ...state,
        dealer: {
          ...state.dealer,
          data: action.payload.data,
        },
      }
    default:
      return state
  }
}
