import React from 'react'
import { Modal } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'
import ContactPage from './contact-page'

const ContactPageModal = ({ visible, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      id="contactModal"
      title={t('contact:headline')}
      shown={visible}
      onClose={onClose}
      onClickOutside={onClose}
    >
      <ContactPage />
    </Modal>
  )
}

export default ContactPageModal
