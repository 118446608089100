import * as actions from '../../redux/features/catalog.redux'
import { store } from '../../redux'
import { getApiBrand, CONNECTION_TIMEOUT } from '../../../config'
import { isMercadoLibre } from '../../data/utils'
import { axios } from '..'
import { LoggerDebug, LoggerError } from '../../common/logger'

const {
  REACT_APP_PROXY_API_KEY,
  REACT_APP_GET_VEHICLE_MODELS,
  REACT_APP_GET_VEHICLE_MODELS_WITH_STOREFRONT,
} = process.env

function getVehicleModels(isPfa, modelBrand, used) {
  let requestBrand = getApiBrand(modelBrand)
  let cid = ''
  let condition =
    ((typeof used === 'string' || used instanceof String) && used === 'used') ||
    (typeof used === 'boolean' && used)
      ? 'used'
      : 'new'
  try {
    const storefrontData = store.getState().storefront?.data
    if (storefrontData && Object.entries(storefrontData).length) {
      const {
        id,
        vehicleData: { brand },
        usedCar,
      } = storefrontData

      requestBrand = getApiBrand(brand || modelBrand)
      cid = id ? `/${id}` : ''
      if (usedCar) {
        condition = usedCar ? 'used' : 'new'
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    LoggerDebug(`Not using storefront data => new journey instance: ${error}`)
  }

  const brand =
    condition === 'used' && requestBrand !== 'audi' ? 'dwa' : requestBrand
  const vehicleModelsUrl = `${REACT_APP_GET_VEHICLE_MODELS}/${condition}/${brand}/${isPfa}`
  const vehicleModelsUrlWithStorefront = `${REACT_APP_GET_VEHICLE_MODELS_WITH_STOREFRONT}/${condition}/${requestBrand}/${isPfa}${cid}`
  const entryPoint = store.getState().storefront.data?.transaction?.entryPoint

  let vehicleModelsReq

  if (
    cid &&
    !isMercadoLibre() &&
    modelBrand !== 'audi' &&
    entryPoint !== 'standalone'
  ) {
    vehicleModelsReq = axios.get(vehicleModelsUrlWithStorefront, {
      headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
      timeout: CONNECTION_TIMEOUT * 3,
    })
  } else {
    vehicleModelsReq = axios.get(vehicleModelsUrl, {
      headers: { 'X-API-KEY': REACT_APP_PROXY_API_KEY },
      timeout: CONNECTION_TIMEOUT * 3,
    })
  }

  return vehicleModelsReq.catch(error => {
    try {
      if (axios.isCancel(error)) {
        LoggerDebug('cancelled')
      } else {
        return Promise.resolve({})
      }
    } catch (error) {
      return Promise.resolve({})
    }
  })
}

export function loadVehicleModelsToRedux(isPfa, brand, used) {
  return internalLoadVehicleModelsToRedux(store.dispatch, isPfa, brand, used)
}

function internalLoadVehicleModelsToRedux(dispatch, isPfa, brand, used) {
  dispatch(actions.pending())
  return getVehicleModels(isPfa, brand, used)
    .then(response => {
      dispatch(actions.done(response))
      return response
    })
    .catch(error => {
      LoggerError('getVehicleModels', error)
    })
}

export function mapBrandUsedNew(brand, used) {
  if (used === 'used' && brand === 'vw') {
    return 'volkswagen'
  }
  return brand
}
