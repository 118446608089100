export const CONNECTION_TIMEOUT = 30000

export const NUMBER_FORMAT = 'en-US'

export const CURRENCY = 'USD'

export const CURRENCY_SYMBOL = '$'

export const getBrandName = () => {
  return process.env.REACT_APP_WEBSITE_BRAND === 'vw6'
    ? 'vw'
    : process.env.REACT_APP_WEBSITE_BRAND
}

export const shouldShowLegend = usedNew => {
  const brandName = getBrandName() ?? ''

  if (brandName === 'audi' && usedNew === 'used') {
    return false
  }

  return (
    brandName.toLowerCase() !== 'dwa' &&
    usedNew &&
    !(brandName.toLowerCase() === 'vwfs' && usedNew === 'used')
  )
}

export const getApiBrand = brand => {
  switch (brand) {
    case 'audi':
      return 'audi'
    case 'vwfs':
    case 'vw6':
      return 'vw'
    case 'vwcv':
      return 'vw-lcv'
    default:
      return brand
  }
}

export function getRequestBrand(brand, usedNew) {
  if (usedNew === 'used' && brand !== 'audi') {
    return 'dwa'
  }
  return getApiBrand(brand)
}

export const getSubmitApiBrand = brand => {
  switch (brand) {
    case 'dwa':
    case 'vw-lcv':
      return 'vwfs'
    default:
      return brand
  }
}

const getEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT
    ? process.env.REACT_APP_ENVIRONMENT.toUpperCase()
    : 'error'
}

export const analyticsOccupationMap = {
  employee: 'Private',
  other: 'Business',
}

export const analyticsBrandsMap = {
  vw: 'VW',
  seat: 'Seat',
  audi: 'Audi',
  porsche: 'Porsche',
  vwcv: 'VWCV',
}

export const STEP = {
  PERSONAL: 'personalDetails',
  CONTACT: 'contactDetails',
  INCOME: 'incomeDetails',
}

export const ANALYTICS_DEFAULTS = {
  core: {
    stagingEnvironment: getEnvironment(),
    dataLayerVersion: '2.4',
    pageInfo: {
      pageName: null,
      intendedCustomerDeviceType: 'all',
      version: 'Rel. 3',
      releaseDate: '2020-08-07',
      language: 'es',
      market: 'MX',
      publisher: 'DU',
    },
    category: {
      primaryCategory: 'Financing',
      siteType: 'standalone',
    },
    attributes: {
      journeyType: 'customer-facing-product-journey',
      viewChange: null,
      brand: getBrandName(),
      faqText: null,
    },
  },
  error: {
    errorCode: null,
    errorMessage: null,
    errorCausingURL: null,
  },
  product: [
    {
      category: 'Finance',
      /*
      The name of the product from the given list. If you cannot find a
      suitable entry for the product sold in the journey, please reach out
      to the web analytics team. Please do not add any products to this list
      on your own.

      Type: Enum
      AutoCredit
      ClassicCredit
      Credit
  
  As long as no specific credit type is selected the value is Credit.
  As soon as the customer selected a specific product the value is
  either AutoCredit or ClassicCredit.
      */
      name: 'AutoCredit',
      attributes: {
        /*
        Specifies the type of complete sales process

        Type: Enum
        Hybrid sales
        Online sales
        Partner sales
        */
        typeOfSale: 'Hybrid sales',

        /*
        E.g. monthly payment for insurance or leasing contract or an
        installment. Please provide exact (float) value
        Type: Float
        The currency is €.
        */
        recurringPayment: null,

        /*
        Planned duration of contract; please use value -1 if indefinite
        Type: Integer
        */
        duration: null,

        /*
        Unit for planned duration of contract.
        Type: Enum
        WEEK
        TWO-WEEKS
        MONTH
        TWO-MONTHS
        QUARTER
        SIX-MONTHS
        YEAR
        */
        durationUnit: 'MONTH',

        /*
        The date of actual registration of vehicle. Only if part of the
  contract.
        Type: Date (YYYY-MM-DD)
        This is a particular important value. Do not skip it due to
        management request!
        */
        registrationDateOwner: null,

        /*
        This is the repayment of the outstanding principal sum made at the
        end of a loan period, interest only having been paid hitherto.
        Type: Float
        */
        balloonPayment: null,

        /*
        Acceptance criteria:
        The net loan amount
        Type: Float

        */
        netLoanAmount: null,

        /*
        The gross loan amount. This value is the total sum of the special
        loan amount plus all credit costs (including all interests and all
        fixed costs). It is also the amount which the customer will pay in
        the end. The currency is €.
        Type: Float
        */
        grossLoanAmount: null,

        /*
        The amount of deposit that the customer makes in the context of a
        financing product. The currency is €.
        Type: Float
        */
        downPaymentAmount: null,

        /*
        The nominal interest rate expressed as a factor applied to the net
        loan amount. It includes only the credit costs that are caused by
        the nominal interest itself.
        Type: Float
        */
        nominalInterestRate: null,

        /*
        The amount of interests caused by the nominalInterestRate over the
        entire timespan of the credit. It does not include other credit
        costs than nominal interests. The currency is €.
        Type: Float
        */
        nominalInterestAmount: null,
      },
      // Aligned with BFF data model!
      vehicleModel: [
        {
          currency: 'MXN',

          /* The manufacturer of the current vehicle.
          Type: Enum
          Audi
          Bentley
          Cupra
          Ducati
          MAN
          Porsche
          Scania
          Seat
          Skoda
          VW
          VWCV
          VWFS
          */
          manufacturer: null,

          /*
          The main name of the current car.

          Type: string, for example
          A3
          Kodiaq
          XC60
          Auris
          Golf
          */
          name: null,

          /*
          The first subcategory of the current car in manufacturers product
          program.
          Type: string
          For example
          GTI
          */
          modelVariation: null,

          /*
          The year when the model has been released.
          Type: string
          Format: YYYY
          */
          year: null,

          /*
          The second subcategory of the current car in manufacturers product
          program.
          Type: string
          For example
          Sportline
          Momentum
          Sportback
          Performance
          */
          modelLine: null,

          /*
          Long description of the vehicle model
          Type: String
          Example:
          Golf GTI Performance
          A3 Sportback 1.5 TFSI Black Edition
          */
          descriptionLong: null,

          /*
          If it is a vehicle with a special warranty (Das Weltauto, TradePort),
          true, otherwise false
          Type: Boolean
          */
          certifiedPreOwned: null,

          /* The final vehicle price (local currency). 
          The unit is not part of the value.
          Type: Integer
          Unit local currency
          Original value: 15388 PLN
          Data layer value: 15388
          */
          endPrice_localCurrency: null,

          /* The type of usage
          Enum:
          Private
          Business
          Both
          */
          typeOfUse: null,
          engine: {
            /* The engines fuel type of the vehicle
            Enum:
            CNG
            Diesel
            Electric
            Hybrid
            Hydrogen
            LPG
            Petrol
            */
            fuelType: null,
          },
        },
      ],
    },
  ],
  dealerData: {
    /*
    The company name of the car dealer.
    Type: string
    */
    companyName: null,
    address: {
      // Dealers address
      street: null,
      // The postal code of dealer’s address.
      zipCode: null,
      // The city of the address.
      city: null,
      /* The region the dealer is located. In Germany this would be the
        federal state.
        */
      state: null,
    },
  },
  form: {
    type: null,
    name: null,
    errorFields: null,
  },
  design: {
    /*
        For responsive design: Thresholds when design changes. E.g. Customer
        has a size of 1500 x 900 -> 1024 x 768 (if this is your threshold).
        Type: string
        Example: 800 x 600
        */
    browserResolutionBreakpoint: null,
  },
  customerData: {
    /*
    Indicates whether the user is currently logged in or not
    Type: Boolean
    */
    loginStatus: false,

    /*
    The user group the customer belongs to. Specified values are:
    - private
    - business
    */
    loggedInUserGroup: null,

    /*
    The year the customer was born.
    Format: YYYY
    */
    yearOfBirth: null,

    /*
    The gender of the customer
    Type: Enum
    MALE
    FEMALE
    */
    gender: null,
    address: {
      /* If the customer provides multiple addresses, we expect the zip
        code of latest address here.
        */
      zipCode: null,
    },
  },
  event: [
    {
      eventInfo: {
        eventType: null,
        eventAction: null,
        eventTargetURL: null,
      },
    },
  ],
}

export const bronsonConfig = {
  audi: {
    theme: 'audi',
    version: '15.1.0',
  },
  vw: {
    theme: 'vw6',
    version: '15.1.0',
  },
  vwcv: {
    theme: 'vw6',
    version: '15.1.0',
  },
  vwvc: {
    theme: 'vw6',
    version: '15.1.0',
  },
  vwn: {
    theme: 'vw6',
    version: '15.1.0',
  },
  vw6: {
    theme: 'vw6',
    version: '15.1.0',
  },
  seat: {
    theme: 'seat',
    version: '15.1.0',
  },
  skoda: {
    theme: 'skoda',
    version: '15.1.0',
  },
  vwfs: {
    theme: 'bluelabel',
    version: '15.1.0',
  },
  cupra: {
    // Temporary values.
    theme: 'generic',
    version: '15.1.0',
  },
  porsche: {
    theme: 'generic',
    version: '15.1.0',
  },
  dwa: {
    theme: 'generic',
    version: '15.1.0',
  },
}
