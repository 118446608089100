export const FETCH_AMORTIZATION_TABLE_PENDING =
  'amortizationTable//FETCH_AMORTIZATION_TABLE_PENDING'

export const FETCH_AMORTIZATION_TABLE_DONE =
  'amortizationTable//FETCH_AMORTIZATION_TABLE_DONE'

export const FETCH_AMORTIZATION_TABLE_ERROR =
  'amortizationTable//FETCH_AMORTIZATION_TABLE_ERROR'

export const RESET_AMORTIZATION_TABLE =
  'amortizationTable//RESET_AMORTIZATION_TABLE'

export function pending() {
  return {
    type: FETCH_AMORTIZATION_TABLE_PENDING,
    payload: {},
  }
}

export function done(data) {
  return {
    type: FETCH_AMORTIZATION_TABLE_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_AMORTIZATION_TABLE_ERROR,
    payload: {
      err,
    },
  }
}

export function reset() {
  return {
    type: RESET_AMORTIZATION_TABLE,
  }
}

export function getAmortizationTableData(store) {
  return store.amortizationTable.data.amortizationTable
}

const initialState = {
  pending: false,
  data: [],
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_AMORTIZATION_TABLE_PENDING:
      return {
        ...state,
        data: [],
        pending: true,
        error: null,
      }

    case FETCH_AMORTIZATION_TABLE_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_AMORTIZATION_TABLE_ERROR:
      return {
        ...state,
        data: [],
        pending: false,
        error: true,
      }
    case RESET_AMORTIZATION_TABLE:
      return initialState

    default:
      return state
  }
}
