import {
  NUMBER_FORMAT,
  CURRENCY,
  CURRENCY_SYMBOL,
  getBrandName,
} from '../../config'

export const formatNumber = value => {
  if (value) {
    let number = parseFloat(value, 10)
    number = Intl.NumberFormat(NUMBER_FORMAT, {
      style: 'decimal',
    }).format(number)
    return number
  }
  return null
}

export const formatCurrency = (value, addOn = 'code') => {
  if (value) {
    let number = parseFloat(value, 10)
    number = Intl.NumberFormat(NUMBER_FORMAT, {
      style: 'currency',
      currency: CURRENCY,
    }).format(number)
    number =
      addOn === 'symbol'
        ? number.replace(CURRENCY, CURRENCY_SYMBOL).replace(/\s/g, '')
        : number
    return number
  }
  return null
}

export const formatCurrencyInputValue = inputValue => {
  const value = inputValue.replace(/,/g, '')
  if (value.length) {
    if (value.indexOf('.') > -1) {
      const [number, decimal] = value.split('.')
      const decimalValue = `00${decimal}`.slice(-2)
      return `${number}.${decimalValue}`
    }
    return `${value}.00`
  }
  return null
}

export const formatWithMonths = (val, t) => {
  if (!val) {
    return ''
  }
  const monthsLabel =
    val === 1 ? ` ${t('config:month')}` : ` ${t('config:months')}`
  return `${val} ${monthsLabel}`
}

/* evaluates '{env.XXX}' in string
 * and replace with value from process.env variables */
export const evaluateEnvironmentVariables = str => {
  let res = str
  Object.keys(process.env).forEach(key => {
    res = res.replace(`{env.${key}}`, process.env[key])
  })
  return res
}

export const getUriParameter = params => {
  return encodeURI(
    Object.entries(params)
      .map(p => p.join('='))
      .join('&')
  )
}

/* inputFormat: 15.01.2020, output: 2020-01-15 */
export const convertDate = date => {
  if (!date) return null
  if (date.match(/^\d{4}[-]\d{1,2}[-]\d{1,2}$/)) return date
  if (date.match(/^\d{1,2}[.]\d{1,2}[.]\d{4}$/)) {
    const dateValues = date.split('.')
    const day = `00${dateValues[0]}`.slice(-2)
    const month = `00${dateValues[1]}`.slice(-2)
    return `${dateValues[2]}-${month}-${day}`
  }
  return new Error('invalid date format')
}

export const formatName = (firstName, secondaryName = '') => {
  const f = firstName ? firstName.trim() : ''
  const s = secondaryName ? secondaryName.trim() : ''
  const whiteSpace = f.length && s.length ? ' ' : ''
  return `${f}${whiteSpace}${s}`
}

export const documentTitle = (t, page) => {
  const brandName = getBrandName()
  return `${t(`config:pageTitle:${brandName}`)} | ${t(`${page}:pageTitle`)}`
}

export const removeNullFromArray = array => {
  return array.filter(el => {
    return el != null
  })
}
