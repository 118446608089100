import React from 'react'
import { ContentSection as BrContentSection } from '@vwfs-bronson/bronson-react'

export const ContentSection = ({ children, altBackground, small }) => {
  const className = `o-page-wrap  ${
    small === undefined ? null : 'o-page-wrap--small'
  }`
  return (
    <BrContentSection
      className="o-content-section"
      altBackground={altBackground === undefined}
    >
      <div className={className}>{children}</div>
    </BrContentSection>
  )
}
